export const POUNDS_PER_KILO = 2.2
export const POUNDS_PER_STONE = 14

export function convertToPounds(weight, units) {
  switch(units) {
    case 'lb':
      return weight
    case 'kg':
      return weight * POUNDS_PER_KILO
    case 'st':
      return weight * POUNDS_PER_STONE
    default:
      console.error(`Unrecognized units ${units}`)
  }
}

export function convertFromPounds(weight, units) {
  switch(units) {
    case 'lb':
      return weight
    case 'kg':
      return weight / POUNDS_PER_KILO
    case 'st':
      return weight / POUNDS_PER_STONE
    default:
      console.error(`Unrecognized units ${units}`)
  }
}

export function convertWeightUnits(weight, oldUnits, newUnits) {
  if(weight === null)
    return null

  if(Number.isNaN(weight))
    return NaN

  const weightInPounds = convertToPounds(weight, oldUnits)
  return convertFromPounds(weightInPounds, newUnits)
}

