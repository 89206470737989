import React from 'react'
import {connect} from 'react-redux'

import {postComment} from './actions'

import TextInput from '../../components/forms/TextInput'

export class CommentBox extends React.Component {
  constructor(props) {
    super(props)

    this.state = { text: ''}

    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }
  // TODO reuse this to edit a comment

  render() {
    const {text} = this.state

    return <div className='comment add'>
      <form
          onSubmit={this.handleSubmit}
          onChange={this.handleChange}>
        <TextInput placeholder='Add a comment…' value={text} />

      </form>
    </div>
  }

  handleChange(event) {
    const value = event.target.value
    this.setState({text: value})
  }

  handleSubmit(event) {
    event.preventDefault()

    const {text} = this.state
    if(text && text.length > 0) {
      const {activity, postComment} = this.props
      postComment(activity, {comment: {text}})
      this.setState({text: ''})
    }

  //  TODO have a separate CommentPosted action
  // Handle invalid comments
  }

}

const mapDispatchToProps = {
  postComment
}

export default connect(null, mapDispatchToProps)(CommentBox)

