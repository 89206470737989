import React, {Component} from 'react'
import {connect} from 'react-redux'

import {t} from '../lib/localize'
import {setPageTitle} from '../lib/dom'

import EmailInput from '../components/forms/EmailInput'
import PasswordInput from '../components/forms/PasswordInput'
import SubmitButton from '../components/forms/SubmitButton'
import Errors from '../components/forms/Errors'
import {updateFormState} from '../components/forms/functions'

import {currentSlimmer} from '../slimmers/selectors'

import {redirectAfterSignIn, rememberEmail, signIn} from './actions'

import ResetPasswordLink from './ResetPasswordLink'
import RegistrationLink from './RegistrationLink'

export class SignIn extends Component {
  constructor(props){
    super(props)

    this.state = {
      email: (props.email || ''),
      password: '',
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  componentDidMount() {
    const {signedIn, me, redirectAfterSignIn} = this.props
    if(signedIn) {
      redirectAfterSignIn(me)
    }
  }

  render() {
    setPageTitle(t('sessions.signin.title'))
    const {email, password} = this.state
    const errors = this.props.errors || {}

    return (
        <div className='session'>

          <div className='session-panel sign-in'>
            <h2>{t('sessions.signin.welcome')}</h2>

            <form className='form'
                  onSubmit={this.handleSubmit}
                  onChange={this.handleChange}>

              <Errors errors={errors.base} className='base' />
              <EmailInput id='email'
                          autoFocus={true}
                          required={true}
                          placeholder={t('sessions.email.placeholder')}
                          value={email}
                          errors={errors.email}
              />
              <PasswordInput id='password'
                             required={true}
                             placeholder={t('sessions.password.placeholder')}
                             value={password}
                             errors={errors.password}
              />
              <SubmitButton label={t('sessions.signin.submit')} />
            </form>
          </div>

          <div className='links'>
            <RegistrationLink/>
            <ResetPasswordLink />
          </div>
        </div>
    )
  }

  handleChange(event) {
    const state = updateFormState(event)
    this.props.rememberEmail(state.email || this.state.email)
    this.setState(state)
  }

  handleSubmit(event) {
    event.preventDefault()

    const email = this.state.email
    const password = this.state.password
    const credentials = {
        email,
        password
      }
    this.props.signIn(credentials)
  }
}

const mapStateToProps = (state) => {
  const me = currentSlimmer(state)
  const signedIn = me !== null
  const {email, errors} = state.session
  return {
    me,
    signedIn,
    email,
    errors,
  }
}


const mapDispatchToProps = {
  signIn,
  rememberEmail,
  redirectAfterSignIn
}

export default connect(mapStateToProps, mapDispatchToProps)(SignIn)

