import React from 'react'
import {NamePrompt, validateName} from './NamePrompt'

export function InteractiveResponse({slimmer, exchange, response, onSubmit, onChange}) {
  const responsePrompt = findComponent(exchange.prompt, slimmer, response)
  if(responsePrompt == null) return null

  return <div className='responses'>
    <form
        className='form'
        onChange={onChange}
        onSubmit={() => validate(prompt, response, onSubmit)}
        >
      {responsePrompt}
    </form>

  </div>
}

function findComponent(prompt, slimmer, response){
  switch(prompt) {
    case 'name':
      return <NamePrompt slimmer={slimmer} response={response} />

    default:
      return null
  }
}

function validate(prompt, response, submit){
  let errors = []

  switch(prompt) {
    case 'name':
      errors = validateName(response)
      break

    default:
      break
  }

  if(errors.length === 0) {
    submit()
  }
}

