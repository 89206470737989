import React from 'react'

import {isPasswordPage, isRegistrationPage, isSignInPage} from '../navigation/routes'
import RegistrationLink from './RegistrationLink'
import SignInLink from './SignInLink'
import {t} from "../lib/localize";

export default function SessionLinks() {
  let showSignIn = true
  let showRegistration = true

  if(isSignInPage() ) showSignIn = false
  if(isRegistrationPage()) showRegistration = false
  if(isPasswordPage()) showRegistration = false

  const linkLabel = showSignIn && showRegistration
      ? t('registration.short_link')
      : t('registration.link')

  return <div className='session-links'>
    {showRegistration && <RegistrationLink className={'button call-to-action'} label={linkLabel}/>}
    {showSignIn && <SignInLink className={'button secondary'} />}
  </div>
}

