import React from 'react'
import Errors from './Errors'
import {suppressTheStupidWarning} from './functions'

export default function Input (props) {
  const id = props.id
  const type = props.type || 'text'
  const labelText = props.label
  const className = props.className || id
  const errors = props.errors
  const onChange = props.onChange || suppressTheStupidWarning

  const {label, ...inputProps} = props // react complains if label is false

  return (
    <div className={`input ${type} ${className}`}>
      {label && <label htmlFor={id}>{labelText}</label>}
      <input {...inputProps}
             type={type}
             onChange={onChange}
      />
      <Errors errors={errors} />
    </div>
  )
}


