import React from 'react'
import {t} from '../lib/localize'
import {Link} from 'react-router-dom'
import {pathToConversationsMenu} from '../navigation/routes'

export default function ConversationLink({label=true, children}) {
  children = children || <React.Fragment>
    <div className='icon'>{t('characters.cynthia.avatar')}</div>
    {label && <div className='label'>{t('conversation.cynthia.link')}</div>}
  </React.Fragment>

  return <Link to={pathToConversationsMenu()} className='conversations-link'>{children}</Link>
}

