import server from '../../server/SlimmingServer'
import {slimmersLoaded} from '../actions'

export const ACTIVITIES_LOADED = 'activities loaded'
export const LOAD_ACTIVITIES_FAILED = 'load activities failed'
export const POST_COMMENT_FAILED = 'post comment failed'

export function loadActivities(slimmer_id) {
  return async function(dispatch) {
    const response = await server.loadActivities(slimmer_id)
        .catch(error => dispatch(loadActivitiesFailed(error)))

    if(response.activities) dispatch(activitiesLoaded(slimmer_id, response.activities))
    if(response.slimmers)   dispatch(slimmersLoaded(response.slimmers))
  }
}

export function activitiesLoaded(slimmer_id, activities) {
  return { type: ACTIVITIES_LOADED, slimmer_id, activities }
}

export function postComment(activity, comment) {
  return async function (dispatch) {
    await server.postComment(activity.slimmer, activity, comment)
        .catch(error => dispatch(postCommentFailed(error)))
    dispatch(loadActivities(activity.slimmer.id))
  }
}

export function postCommentFailed(error) {
  return { type: POST_COMMENT_FAILED, error }
}

export function loadActivitiesFailed(error) {
  return { type: LOAD_ACTIVITIES_FAILED, error }
}
