import {currentSlimmerId} from '../session/selectors'

function loaded(state) {
  return state.slimmers && state.slimmers.by_id
}

export function allSlimmers(state) {
  if(loaded(state))
    return Object.values(state.slimmers.by_id).sort(compareSlimmers)
  else
    return []
}

function compareSlimmers( a, b ) {
  if ( a.last_visit_at < b.last_visit_at ) return 1
  if ( a.last_visit_at > b.last_visit_at ) return -1
  return 0
}

export function findSlimmer(state, id) {
  if(!loaded(state)) return null

  let slimmer = state.slimmers.by_id[id]
  if (slimmer === undefined) {
    slimmer = {
      id,
      name: id,
      loading: true
    }
  }
  return slimmer
}

export function currentSlimmer(state) {
  const slimmerId = currentSlimmerId(state)

  if (slimmerId)
    return findSlimmer(state, slimmerId)
  else
    return null
}

