import {Server} from './Server'

class SlimmingServer {
  constructor() {
    this.remote = new Server()
  }

  signIn = (credentials) => this.remote.post('/sign_in.json', {credentials})
  signOut = () => this.remote.delete('/sign_out.json')

  resetPassword = (email) => this.remote.post('/passwords.json', {email})
  updatePassword = (slimmer, password) => this.updateSettings(slimmer, {
    slimmer: {
      password: password,
      password_confirmation: password,
    }
  })

  loadSlimmers = () => this.remote.get('/slimmers.json')
  loadSlimmer = id => this.remote.get(`/slimmers/${id}.json`)
  loadActivities = id => this.remote.get(`/slimmers/${id}/activities.json`)

  postComment = (slimmer, activity, data) => this.remote.post(`/slimmers/${slimmer.id}/activities/${activity.id}/comments.json`, data)

  register = (slimmer) => this.remote.post('/slimmers.json', {slimmer})
  // refreshSlimmers = since => this.remote.get(`/slimmers.json?since=${since}`) // Not done yet on the server

  fetchConversation = id => this.remote.get(`/conversations/${id}/history.json`)
  fetchNextExchange = id => this.remote.get(`/conversations/${id}.json`)
  postResponse = (response) => this.remote.post(`/conversations.json`, response)

  updateSettings = (slimmer, data) => this.remote.put(`/slimmers/${slimmer.id}.json`, data)
  postWeight  = (slimmer, data) => this.remote.post(`/slimmers/${slimmer.id}/weights.json`, data)
}

const server = new SlimmingServer()
export default server