import React from 'react'
import WeighInButton from './WeighInButton'
import SettingsLink from '../slimmers/settings/SettingsLink'
import ConversationLink from '../conversations/ConversationLink'
import NextTopicButton from '../conversations/NextTopicButton'

export default function StatusBar({me}) {
  if(! me.signed_in) return null

  let actionButton = null

  if(me.weigh_in_due)
    actionButton = <WeighInButton slimmer={me} />
  else if (me.next_topic)
    actionButton = <NextTopicButton slimmer={me} />
  else
    return null

  return (
      <div id='status-bar' className='status-bar'>
        <ConversationLink label={false}/>
        {actionButton}
        <SettingsLink />
      </div>
  )
}
