import React from 'react'
import ActivitiesList from './activities/ActivitiesList'
import Tabs from '../components/Tabs'
import Info from './Info'
import {connect} from 'react-redux'
import {isSignedIn} from '../session/selectors'

export class ProfileDetails extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      tab: (props.signedIn ? 'activity' : 'info')
    }

    this.onClickTab = this.onClickTab.bind(this)
  }

  render() {
    const tabs = this.props.signedIn
        ? ['activity', 'info']
        : ['info']

    return <div className='profile-details'>
      <Tabs tabs={tabs} selected={this.state.tab} onClick={this.onClickTab}/>
      {this.chooseView()}
    </div>
  }

  chooseView() {
    const {slimmer} = this.props
    switch(this.state.tab) {
      case 'activity':
        return <ActivitiesList slimmer={slimmer}/>

      case 'info':
        return <Info slimmer={slimmer} isSignedIn={this.props.isSignedIn} />

      default:
        return null
    }
  }

  onClickTab(tab) {
    this.setState({tab})
  }
}

const mapStateToProps = (state, props) => {
  const signedIn = isSignedIn(state)

  return {
    ...props,
    signedIn,
  }
}

export default connect(mapStateToProps)(ProfileDetails)

