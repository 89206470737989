import * as ReactGA from 'react-ga'

let analyticsId = null
let googleAnalyticsInitialized = false

export function googleAnalyticsId() {
  if (!analyticsId) {
    const tag = document.querySelector('meta[name=analytics_id]') || {}
    analyticsId = tag.content
  }
  return analyticsId
}

export function initializeGoogleAnalytics(testMode=false) {
  const id = googleAnalyticsId()

  const options = {
    anonymizeIp: true,
    testMode // This isn't working because window.location is not updated. Use the router location?
  }
  ReactGA.initialize(id, options)

  googleAnalyticsInitialized = true
}

export function pageLoaded() {
  if(googleAnalyticsInitialized){
    ReactGA.pageview(window.location.pathname + window.location.search)
  }
}

// TODO hook this up to the router location?

// See https://github.com/react-ga/react-ga
// ReactGA.modalview(modalName)
// ReactGA.event(args)
// ReactGA.timing(args)
// ReactGA.outboundLink(args, hitCallback)
// ReactGA.exception(args)
// ReactGA.initialize('foo', { testMode: true })