
export const UPDATE_VERSION = 'store version'
export const SET_FLASH_MESSAGE = 'set flash message'
export const CLEAR_FLASH_MESSAGE = 'clear flash message'
export const SYSTEM_ERROR = 'system error'
export const SHOW_MODAL = 'show modal'
export const HIDE_MODAL = 'hide modal'

export function showModal(modal, props) {
  return { type: SHOW_MODAL, modal, props }
}

export function hideModal(modal) {
  return { type: HIDE_MODAL, modal }
}

export function showSystemError(error) {
  return { type: SYSTEM_ERROR, error }
}

export function setFlashMessage(error) {
  return { type: SET_FLASH_MESSAGE, error }
}

export default function clearFlashMessage() {
  return {type: CLEAR_FLASH_MESSAGE}
}

export function storeVersion(version) {
  return { type: UPDATE_VERSION, version }
}
