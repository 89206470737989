import React from 'react'
import {t} from '../lib/localize'
import {pathToTopic} from '../navigation/routes'
import {Link} from 'react-router-dom'

export default function NextTopicButton({slimmer}) {
  const topic = slimmer.next_topic
  if(topic)
    return (
        <Link to={pathToTopic('cynthia', topic)}
              className={'talk-now button secondary'}>
          {t('conversation.cynthia.talk_now')}
        </Link>
    )

  return null
}

