import {t} from '../lib/localize'
import server from '../server/SlimmingServer'

import {redirectToProfile, redirectToSignedOut} from '../navigation/redirects'
import {extractValidationErrors} from '../lib/actions'
import clearFlashMessage, {setFlashMessage} from '../app/actions'

export const REGISTERED = 'slimmer registered'
export const REGISTRATION_FAILED = 'registration failed'

export const SET_CURRENT_SLIMMER = 'set current slimmer'

export const SIGNING_OUT = 'signing out'
export const SIGNED_OUT = 'signed out'

export const SIGNING_IN = 'signing in'
export const SIGNED_IN = 'signed in'
export const SIGN_IN_FAILED = 'signed in failed'

export const PASSWORD_REQUEST_SENT = 'password request sent'
export const PASSWORD_REQUEST_FAILED = 'password request failed'
export const SENDING_PASSWORD_REQUEST = 'sending password request'

export const UPDATING_PASSWORD = 'updating password'
export const UPDATED_PASSWORD = 'updated password'
export const UPDATE_PASSWORD_FAILED = 'updated password failed'

export const REMEMBER_EMAIL = 'remember email'

export function setCurrentSlimmer(slimmer) {
  return { type: SET_CURRENT_SLIMMER, slimmer}
}

export function signIn(credentials) {
  return async function (dispatch) {
    dispatch(signingIn())
    const response = await server.signIn(credentials)
        .catch(error => dispatch(signInFailed(error)))
    if(response && response.slimmer) {
      dispatch(signedIn(response.slimmer))
      dispatch(redirectAfterSignIn())
    }
  }
}

export function signingIn() {
  return { type: SIGNING_IN }
}

export function signInFailed(error) {
  let errors = extractValidationErrors(error)
  return { type: SIGN_IN_FAILED, errors }
}

export function signedIn(slimmer) {
  return { type: SIGNED_IN, slimmer }
}

export function register(slimmer) {
  return async function (dispatch) {
    const response = await server.register(slimmer)
        .catch(error => dispatch(registrationFailed(error)))
    if (response && response.slimmers) {
      const slimmer = response.slimmers[0]
      dispatch(registered(slimmer))
      dispatch(redirectAfterSignIn(slimmer))
    }

  }
}

export function registered(slimmer) {
  return { type: REGISTERED, slimmer }
}

export function registrationFailed(error) {
  let errors = extractValidationErrors(error)
  return { type: REGISTRATION_FAILED, errors }
}


export function redirectAfterSignIn(slimmer) {
  // TODO store original url and redirect to that
  return function(dispatch) {
    redirectToProfile(slimmer)
  }
}

export function signOut() {
  return async function (dispatch) {
    dispatch(signingOut())
    await server.signOut()
    dispatch(signedOut())
    redirectToSignedOut()
  }
}

export function signingOut() {
  return { type: SIGNING_OUT }
}

export function signedOut() {
  return { type: SIGNED_OUT }
}


export function resetPassword(email) {
  return async function (dispatch) {
    dispatch(sendingPasswordRequest())
    await server.resetPassword(email)
        .catch(error => dispatch(resetPasswordFailed(error)))
    dispatch(clearFlashMessage())
    dispatch(passwordRequestSent(email))

  }
}

export function updatePassword(slimmer, password) {
  return async function (dispatch) {
    dispatch(updatingPassword())
    await server.updatePassword(slimmer, password)
        .catch(error => dispatch(updatePasswordFailed(error)))
    dispatch(updatedPassword())
    dispatch(setFlashMessage(t('password.change.confirmation')))
    redirectToProfile(slimmer)
  }
}

export function rememberEmail(email) {
  return { type: REMEMBER_EMAIL, email }
}

export function passwordRequestSent(email) {
  return { type: PASSWORD_REQUEST_SENT, email }
}

export function resetPasswordFailed(error) {
  const errors = extractValidationErrors(error)
  return { type: PASSWORD_REQUEST_FAILED, errors }
}

export function sendingPasswordRequest() {
  return { type: SENDING_PASSWORD_REQUEST }
}

export function updatingPassword() {
  return { type: UPDATING_PASSWORD }
}

export function updatedPassword() {
  return { type: UPDATED_PASSWORD }
}

export function updatePasswordFailed(error) {
  const errors = extractValidationErrors(error)
  return { type: UPDATE_PASSWORD_FAILED, errors }
}

