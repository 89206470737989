import React from 'react'
import {connect} from 'react-redux'

function SystemMessages({messages, dismissSystemMessage}) {
  if(messages && messages.length > 0) {
    let counter = 1
    return <div className='system-messages'>
      {messages.map(message => <div className='message' key={counter++}>{JSON.stringify(messages)}</div>)}
    </div>}

  return null
}

const mapStateToProps = state => {
  const messages = state.app.error ? [state.app.error] : []
  return {messages}
}

const mapDispatchToProps = null
// const mapDispatchToProps = {
//   dismissSystemMessage
// }

export default connect(mapStateToProps, mapDispatchToProps)(SystemMessages)
