import React from 'react'
import ReactDOM from 'react-dom'
import {Provider} from 'react-redux'

import {findAppContainer} from '../lib/dom'

import {createSlimmerFromElementData, initialize, loadCurrentSlimmer} from './initialize'
import App from '../app/App'
import {store} from './store'

document.addEventListener('DOMContentLoaded', initializeReact)

function initializeReact() {
  const element = findAppContainer()
  const slimmer = createSlimmerFromElementData(element)

  initialize()
  loadCurrentSlimmer(slimmer)

  const app = <Provider store={store}>
    <App />
    <div id='modals'/>
  </Provider>

  render(app, element)
}

function render(app, element){
  const container = element.appendChild(document.createElement('div'))
  ReactDOM.render(app, container)
}



