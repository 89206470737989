import React from 'react'
import {connect} from 'react-redux'
import {hideModal} from '../../app/actions'
import Dialog from './Dialog'
import Scale from '../../weights/scale/Scale'
import Settings from '../../slimmers/settings/Settings'
import ChooseAvatar from '../../slimmers/settings/ChooseAvatar'

const none = {}

export function ModalDialogs ({modal=none, hideModal}) {
  let dialog = null
  let {id, props} = modal

  switch(id) {
    case 'weigh-in':
      dialog = <Scale onClose={hideModal} {...props} />
      break;

    case 'choose-avatar':
      dialog = <ChooseAvatar onClose={hideModal} {...props} />
      break;

    case 'settings':
      dialog = <Settings onClose={hideModal} {...props} />
      break;

    default:
      return null;
  }

  return <Dialog onClose={hideModal}>{dialog}</Dialog>
}

const mapStateToProps = (state) => {
  const {modal} = state.app
  return { modal }
}

const mapDispatchToProps = { hideModal }
export default connect(mapStateToProps, mapDispatchToProps)(ModalDialogs)
