import React, {Component} from 'react'
import {connect} from 'react-redux'

import {t} from '../lib/localize'
import {setPageTitle} from '../lib/dom'

import EmailInput from '../components/forms/EmailInput'
import SubmitButton from '../components/forms/SubmitButton'
import Errors from '../components/forms/Errors'
import {updateFormState} from '../components/forms/functions'
import Notice from '../components/Notice'

import SignInLink from './SignInLink'
import RegistrationLink from './RegistrationLink'

import {rememberEmail, resetPassword} from './actions'


export class ResetPassword extends Component {
  constructor(props){
    super(props)

    this.state = {
      email: (this.props.email || ''),
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  render() {
    setPageTitle(t('password.reset.title'))

    const {email} = this.state
    const {passwordResetSentTo} = this.props
    const errors = this.props.errors || {}

    return (
        <div className='session'>
          <div className='session-panel reset-password'>
            <h2>{t('password.reset.title')}</h2>
            <form className='form'
                  onSubmit={this.handleSubmit}
                  onChange={this.handleChange}>

              <Errors errors={errors.base} className='base' />
              <EmailInput id='email'
                          autoFocus={true}
                          required={true}
                          placeholder={t('sessions.email.placeholder')}
                          value={email}
                          errors={errors.email}
              />
              <SubmitButton label={t('password.reset.submit')} />
            </form>

            {passwordResetSentTo && <Notice>{t('password.reset.sent', {email: passwordResetSentTo})}</Notice>}
          </div>

          <div className='links'>
            <SignInLink />
            <RegistrationLink />
          </div>
        </div>
    )
  }

  handleChange(event) {
    const state = updateFormState(event)
    this.props.rememberEmail(state.email)
    this.setState(state)
  }

  handleSubmit(event) {
    event.preventDefault()
    this.props.resetPassword(this.state.email)
  }
}

const mapStateToProps = (state) => {
  const {email, errors, passwordResetSentTo} = state.session
  return {
    email,
    errors,
    passwordResetSentTo
  }
}


const mapDispatchToProps = {
  resetPassword,
  rememberEmail,
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword)

