import React from 'react'
import {NPC} from '../characters/NPC'

export function IntroPanel() {
  return <div className='panel intro'>
    <h2>Doctor Cynthia's Office</h2>
    <NPC name='cynthia'/>
  </div>
}

