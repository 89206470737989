import React from 'react'

export default function Action ({label, className='', description, onClick, children}) {
  const css = `action ${className}`
  return (
      <a href='#'
         className={css}
         title={description}
         onClick={onClick}>
        {label || children}
      </a>
  )
}
