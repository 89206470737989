import React from 'react'
import RadioButton from './RadioButton'

export default function RadioGroup ({id, className='', options, value, onChange}) {
  // TODO Deal with the case where the label doesn't match the value
  return (
    <div className={`input radio-group ${className}`}>
      {options.map((option) => <RadioButton
          key={`${id}-${option}`}
          name={id}
          value={option}
          checked={option === value}
          onChange={onChange}
          />
      )}
    </div>
  )
}


