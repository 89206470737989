import React from 'react'

function Errors ({errors=[], className=''}) {
  if (errors.length === 0)
    return null

  if(typeof(errors) === 'string')
    errors = [errors]

  const css = `errors ${className}`
  let counter = 1

  return (
    <div className={css}>
      {errors.map(error => <div className='error' key={counter++}>{error}</div>)}
    </div>
  )
}

export default Errors
