import React, {Component} from 'react'
import {connect} from 'react-redux'

import FlipMove from 'react-flip-move'

import {setPageTitle} from '../lib/dom'
import {pageLoaded} from '../lib/analytics'

import ScrollIntoView from '../components/ScrollIntoView'
import WithPolling from '../components/WithPolling'

import {loadSlimmers} from './actions'
import {allSlimmers, currentSlimmer} from './selectors'

import Card from './Card'

export class Slimmers extends Component {
  constructor(props) {
    super(props)
    this.refresh = this.refresh.bind(this)
    this.fillers = Array(5).fill().map((_item, index) => {
      return {id: index, name: '…', url: '#'}
    })
  }

  render() {
    return (
        <ScrollIntoView>
          <WithPolling interval={30} decay={10} longDelay={600} onTick={this.refresh}>
            <FlipMove typeName='ul' className='slimmers list'>
              {this.strangers()}
            </FlipMove>
          </WithPolling>
        </ScrollIntoView>
    )
  }

  strangers() {
    const  {me, slimmers} = this.props

    let strangers = [
      me,
      ...slimmers.filter(slimmer => slimmer !== me),
    ]

    if(strangers.length < 2) {
      strangers = [...strangers, ...this.fillers]
    }

    return strangers.map(slimmer => <li key={slimmer.id}><Card slimmer={slimmer}/></li>)
  }

  componentDidMount() {
    pageLoaded()
    setPageTitle()
  }

  refresh() {
    this.props.loadSlimmers()
  }
}

const mapStateToProps = state => {
  const me = currentSlimmer(state) || {id: 'missing'}
  const slimmers = allSlimmers(state)

  return {
    slimmers,
    me
  }
}

const mapDispatchToProps = {
  loadSlimmers,
}

export default connect(mapStateToProps, mapDispatchToProps)(Slimmers)
