export function convertToMap(array, key='id') {
  const pairs = array.map(item => ({[item[key]]: item}))
  return Object.assign(
      {},
      ...pairs
  )
}

export function any(hash) {
  return hash && Object.keys(hash).length > 0
}

export function empty(hash) {
  return ! any(hash)
}

export function uniquifyArray(array) {
  return [...new Set(array)]
}

export function flatten(array) {
// There's a JS function .flat() but it's not very widely supported
// unlike Ruby's flatten this just does one level of an array of arrays
  return [].concat(...array)
}

export function removeKeyFromMap(map, key) {
  const {[key]: value, ...withoutKey} = map
  return withoutKey
}