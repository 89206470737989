import React from 'react'

export default function Tabs({tabs, selected, align='center', onClick}) {
  const css = `align-${align}`
  return (
      <div className={`tabs ${css}`}>
        { tabs.map(tab => <Tab key={tab}
                               selected={tab === selected}
                               onClick={() => onClick && onClick(tab)}
        >{tab}</Tab>) }
      </div>
  )
}


export function Tab ({onClick, enabled=true, selected=false, className='', children}) {
  const cssEnabled = enabled ? '' : 'disabled'
  const cssSelected = selected ? 'selected' : ''
  const css = `tab ${className} ${cssEnabled} ${cssSelected}`

  return <a className={css} onClick={onClick}>{children}</a>
}
