import {t} from './localize'

export function setPageTitle(title) {
  if (title)
    document.title = `${t('site.title')} | ${title}`
  else
    document.title = t('site.title')
}

export function getCSRFToken() {
  const csrfTag = document.querySelector('meta[name=csrf-token]') || {content: 'missing-csrf-token'}
  return csrfTag.content
}

export function findModalRoot() {
  return document.getElementById('modals')
}

export function findAppContainer() {
  return document.querySelector('#app-container')
}

export function findFlashContainer() {
  return document.querySelector('#flash')
}

