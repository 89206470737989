import React from 'react'
import {suppressTheStupidWarning} from './functions'

export default function RadioButton ({name, value, checked, onChange}) {
  return (
      <label>
        <input type='radio'
               name={name}
               value={value}
               checked={checked}
               onChange={onChange || suppressTheStupidWarning}
        />
        {value}
      </label>
  )
}


