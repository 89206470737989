import React from 'react'
import {t} from '../../lib/localize'
import TextInput from '../../components/forms/TextInput'
import SubmitButton from '../../components/forms/SubmitButton'
import {MAX_NAME_LENGTH, MIN_NAME_LENGTH} from '../../slimmers/settings/Settings'

const NAME_PATTERN = /^[\-/A-Za-z\u00C0-\u017F ]+$/

export function NamePrompt({slimmer, response}) {
  const name = response === null ? slimmer.name : response
  const errors = validateName(response)
  const valid = errors.length === 0

  return <div className='response prompt name'>
    <TextInput label={t('conversation.prompt.name')}
               value={name}
               autoFocus={true}
               minLength={MIN_NAME_LENGTH}
               maxLength={MAX_NAME_LENGTH}
               errors={errors}
    />
    {valid && <SubmitButton />}
  </div>
}

// return an array of errors or [] if valid
export function validateName(name) {
  if(name === null || name.length === 0) {
    return ['Enter your name']
  }

  if(name.match(NAME_PATTERN)) {
    return []
  }

  return ['Letters and numbers only.\nSorry!']
}