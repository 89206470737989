import React, {Component} from 'react'
import {connect} from 'react-redux'

import {t} from '../lib/localize'
import {setPageTitle} from '../lib/dom'

import EmailInput from '../components/forms/EmailInput'
import SubmitButton from '../components/forms/SubmitButton'
import Errors from '../components/forms/Errors'
import {updateFormState} from '../components/forms/functions'

import SignInLink from '../session/SignInLink'

import {register, rememberEmail} from '../session/actions'
import PrivacyPolicy from '../pages/PrivacyPolicy'

export class Registration extends Component {
  constructor(props){
    super(props)

    this.state = {
      email: (this.props.email || ''),
      terms: true,
      submitted: false
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  render() {
    setPageTitle(t('registration.title'))

    const {email} = this.state
    const errors = this.props.errors || {}
    // Skip the terms for now
    // const acceptWarning = (submitted && ! terms) ? [t('registration.terms.required')] : []

    return (
        <div className='session'>
          <div className='session-panel registration'>
            <h2>{t('registration.title')}</h2>
            <form className='form'
                  onSubmit={this.handleSubmit}
                  onChange={this.handleChange}>

              <Errors errors={errors.base} className='base' />
              <EmailInput id='email'
                          autoFocus={true}
                          required={true}
                          placeholder={t('sessions.email.placeholder')}
                          value={email}
                          errors={errors.email}
              />
              {/*<Checkbox id='terms'*/}
              {/*            label={t('registration.terms.label')}*/}
              {/*            checked={terms}*/}
              {/*            errors={acceptWarning}*/}
              {/*/>*/}
              <SubmitButton label={t('registration.button.submit')} />
            </form>
          </div>

          <div className='links'>
            <SignInLink />
            <PrivacyPolicy />
          </div>
        </div>
    )
  }

  handleChange(event) {
    const state = updateFormState(event)
    this.props.rememberEmail(state.email)
    this.setState(state)
  }

  handleSubmit(event) {
    event.preventDefault()

    const {email, terms} = this.state
    if(terms) this.props.register({email})
    else this.setState({submitted: true})
  }
}

const mapStateToProps = (state) => {
  const {email, errors} = state.session
  // const {errors} = state.slimmers
  return {
    email,
    errors,
  }
}


const mapDispatchToProps = {
  register,
  rememberEmail,
}

export default connect(mapStateToProps, mapDispatchToProps)(Registration)

