import React from 'react'
import DailyExcess from '../weights/DailyExcess'
import {t} from '../lib/localize'
import ConversationLink from '../conversations/ConversationLink'
import SettingsLink from './settings/SettingsLink'

export default function Info({slimmer, signedIn}) {
  return (
      <div className='info'>
        {sectionAboutDailyDeficit(slimmer)}
        {sectionAboutProgressChart(slimmer)}
        {sectionAboutAvatar(slimmer)}
      </div>
  )
}

function sectionAboutDailyDeficit(slimmer) {
  const dailyExcess = <DailyExcess slimmer={slimmer} inline={true}/>
  return <section>
    <h2>Daily Deficit (or Excess)</h2>
    {slimmer.excess < 0 && blurbAboutDeficit(dailyExcess)}
    {slimmer.excess === 0 && blurbAboutZeroDeficit(dailyExcess)}
    {slimmer.excess > 0 && blurbAboutExcess(dailyExcess)}
    <p><ConversationLink>{t('characters.cynthia.name')} {t('characters.cynthia.avatar')}</ConversationLink> can tell you
      more about your daily deficit.</p>
  </section>
}

function blurbAboutDeficit(dailyExcess) {
  return <React.Fragment>
    <p>
      The green {dailyExcess} means you are burning more calories than you eat. 🎉🎉🎉
      If you have a daily deficit of 500 calories, you'll lose about one pound a week (about half a kilo).
    </p>
  </React.Fragment>
}

function blurbAboutExcess(dailyExcess) {
  return <React.Fragment>
    <p>
      The red {dailyExcess} means you are eating more calories than you burn. 😭😭😭
      You need a calorie deficit to lose weight.
    </p>
  </React.Fragment>
}

function blurbAboutZeroDeficit(dailyExcess) {
  return <React.Fragment>
    <p>The {dailyExcess} in the box tells you that you are neither gaining nor losing weight.</p>
  </React.Fragment>
}

function sectionAboutProgressChart(slimmer) {
  return <section>
    <h2>Progress Chart</h2>
    <p>
      You've probably noticed that your weight can fluctuate quite a bit from day to day.
      The blue trend line on your progress chart smooths out the ups and downs.
    </p>
    <p>
      If you've had too much 🍕 and your weigh-in is above the trend line, the chart will show a red dot.
      If it's below the line you'll see a green dot. 🙌
    </p>
    <p>
      If you have entered a target weight in your settings <SettingsLink />, the dots will turn to
      gold when you reach your target weight. 😊
    </p>
  </section>
}

function sectionAboutAvatar(signedIn) {
  if(!signedIn) return null

  return <section>
    <h2>Avatar</h2>
    <p>
      You can click on your avatar to change it.
    </p>
  </section>
}
