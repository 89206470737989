import React, {Component} from 'react'
import {connect} from 'react-redux'
import {setPageTitle} from '../lib/dom'
import {pageLoaded} from '../lib/analytics'
import {t} from '../lib/localize'
import {fetchConversation, fetchNextExchange, postResponse} from './actions'
import {findConversation} from './selectors'
import {Panel} from './Panel'
import {currentSlimmer} from '../slimmers/selectors'
import {ResponseOptions} from './ResponseOptions'
import {IntroPanel} from './IntroPanel'
import {redirectToHome} from '../navigation/redirects'
import {InteractiveResponse} from './prompts/InteractiveResponse'
import ScrollIntoView from '../components/ScrollIntoView'


// Figure out why name prompt response does not work

export class Conversation extends Component {
  constructor(props) {
    super(props)

    this.state = {
      current: null,
      response: null,
      show_responses: false
    }

    this.listRef = React.createRef()
    this.currentRef = React.createRef()

    this.onChangeResponse = this.onChangeResponse.bind(this)
    this.onClickResponse = this.onClickResponse.bind(this)
    this.onSubmitResponse = this.onSubmitResponse.bind(this)
    this.scrollToCurrentPanel = this.scrollToCurrentPanel.bind(this)
    this.conversationIsDone = this.conversationIsDone.bind(this)
  }

  render() {
    const {slimmer, conversation} = this.props
    const {exchanges, current} = conversation
    const {response} = this.state
    const show_responses = current && this.state.show_responses

    return (
        <ScrollIntoView>
          <div className='conversation'>
            <ul className='panels' ref={this.listRef}>
              <li><IntroPanel /></li>

              {exchanges.map((exchange) => <li key={exchange.id}>
                <Panel exchange={exchange} slimmer={slimmer} />
              </li>)}

              {current && <li key={current.id} ref={this.currentRef}>
                <Panel exchange={current}
                       active={true}
                       slimmer={slimmer} />
              </li>}
            </ul>

            {show_responses && <ResponseOptions exchange={current}
                                                onClick={this.onClickResponse} />}
            {show_responses && <InteractiveResponse exchange={current}
                                                    slimmer={slimmer}
                                                    response={response}
                                                    onChange={this.onChangeResponse}
                                                    onSubmit={this.onSubmitResponse} />}
          </div>
        </ScrollIntoView>
    )
  }

  componentDidMount() {
    setPageTitle(this.pageTitle())
    pageLoaded()
    this.props.fetchConversation('cynthia')
    this.props.fetchNextExchange(this.props.topic)
  }

  componentWillUnmount() {
    if (this.timer)
      clearTimeout(this.timer)
  }

  componentDidUpdate(prevProps) {
    const conversation = this.props.conversation

    const before = prevProps.conversation.current
    const after = conversation.current

    if (after !== before) {
      const action = after ? this.scrollToCurrentPanel : this.conversationIsDone
      this.timer = setTimeout(action, 1500)
    }
  }

  scrollToCurrentPanel() {
    const list = this.listRef.current
    const panel = this.currentRef.current

    if (panel) {
      const listRight = list.getBoundingClientRect().x + list.getBoundingClientRect().width
      const panelRight = panel.getBoundingClientRect().x + panel.getBoundingClientRect().width

      const toScroll = panelRight - listRight
      if(toScroll > 0) {
        let increment = toScroll / 50
        if(increment < 6) increment = 6
        list.scrollLeft = list.scrollLeft + increment
        this.timer = setTimeout(this.scrollToCurrentPanel, 10)
      }
      else {
        this.setState({show_responses: true})
      }
    }
  }

  conversationIsDone() {
    redirectToHome()
  }

  pageTitle() {
    return t('conversation.cynthia.title')
  }

  // Slimmer chose a fixed response
  onClickResponse(event) {
    const response = event.target.getAttribute('data-key')
    this.submitResponse(response)
  }

  // Slimmer typed a response
  onChangeResponse(event) {
    const response = event.target.value
    this.setState({response})
  }

  // Slimmer clicked the submit button
  onSubmitResponse() {
    this.submitResponse(this.state.response)
  }

  // The response can come from clicking one of the fixed options or typing into a form
  submitResponse(response) {
    const {slimmer, conversation} = this.props
    const {current} = conversation
    const data = {
      id: 'cynthia',
      conversation: {
        key: current.key,
        topic_key: current.topic_key,
        response: response
      }
    }
    this.props.postResponse(slimmer, data)
    this.setState({
      show_responses: false,
      response: null
    })
  }
}

const mapStateToProps = (state, props) => {
  const topic = props.match.params.id
  const conversation = findConversation(state, 'cynthia')
  const slimmer = currentSlimmer(state)

  return {
    slimmer,
    topic,
    conversation
  }
}

const mapDispatchToProps = {
  fetchConversation,
  fetchNextExchange,
  postResponse
}
export default connect(mapStateToProps, mapDispatchToProps)(Conversation)
