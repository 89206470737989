import React, {Component} from 'react'
import {connect} from 'react-redux'

import {t} from '../../lib/localize'

import {postWeight} from '../../slimmers/actions'
import SubmitButton from '../../components/forms/SubmitButton'
import {currentSlimmer} from '../../slimmers/selectors'
import RadioGroup from '../../components/forms/RadioGroup'
import Keypad, {BACKSPACE_GLYPH} from './Keypad'
import Readout from './Readout'
import Button from '../../components/Button'
import {convertToPounds, convertWeightUnits, POUNDS_PER_STONE} from '../conversions'
import {convertStringToWeight, convertToDigits, generateInitialDigits} from './Digits'

const DIGIT_KEY_PATTERN = /^[0-9]$/
const UNIT_KEY_PATTERN = /^[klps]$/
const PLUS_OR_MINUS_PATTERN = /^[+-]$/

export class Scale extends Component {
  constructor(props){
    super(props)

    const {weight, units} = props.slimmer // weight is in 'units'
    this.state = this.stateWith(weight, units)

    this.onClick = this.onClick.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleKeyPress = this.handleKeyPress.bind(this)
    this.onChangeUnits = this.onChangeUnits.bind(this)
    this.incrementWeight = this.incrementWeight.bind(this)
    this.decrementWeight = this.decrementWeight.bind(this)
  }

  render() {
    const {slimmer} = this.props
    const {digits, units, complete, largeDelta, no_change} = this.state

    const blurb = slimmer.weigh_in_due ? t('weight.instructions') : t('weight.already_entered')

    return (
        <div className='scale-dialog' onClick={this.onClick}>
          <h2 className='title'>{t('weight.scale.prompt')}</h2>

          <div className='scale'>
            <div className='readout-row'>
              <Button onClick={this.decrementWeight}>{'—'}</Button>
              <Readout digits={digits} warning={largeDelta}/>
              <Button onClick={this.incrementWeight}>{'+'}</Button>
            </div>

            <Keypad onClick={this.onClick} />
            <SubmitButton enabled={complete}
                          label={no_change ? t('weight.button.no_change') : t('button.ok')}
                          onClick={this.handleSubmit} />
          </div>

          <div className='blurb'>{blurb}</div>

          <RadioGroup id='units'
                      className='units'
                      options={['kg', 'lb', 'st']}
                      value={units}
                      onChange={this.onChangeUnits} />

        </div>
    )
  }

  componentDidMount(){
    document.addEventListener('keydown', this.handleKeyPress, false);
  }

  componentWillUnmount(){
    document.removeEventListener('keydown', this.handleKeyPress, false);
  }

  stateWith(weight, units) {
    const digits = convertToDigits(weight, units)
    const complete = weight && weight > 0 //&& ! digits.includes('-')
    const no_change = complete && this.weightIsTheSame(weight, units)

    // weight is in pounds
    return {
      weight,
      units,
      digits,
      complete,
      no_change
    }
  }

  weightIsTheSame(weight, _units) {
    return weight === this.props.slimmer.weight
  }

  onClick(key) {
    this.handleKey(key)
  }

  handleKeyPress(event) {
    this.handleKey(event.key)
  }

  handleKey(key) {
    switch(true) {
      case DIGIT_KEY_PATTERN.test(key):
        this.handleDigitPress(key)
        break

      case /\./.test(key):
        this.handleDotPress(key)
        break

      case UNIT_KEY_PATTERN.test(key):
        this.handleUnitPress(key)
        break

      case PLUS_OR_MINUS_PATTERN.test(key):
        this.handleIncrementPress(key)
        break

      case key === 'Enter':
        this.handleSubmit()
        break

      case (key === 'Delete' || key === 'Backspace' || key === 'Backspace' || key === BACKSPACE_GLYPH):
        this.handleDeleteKey()
        break
    }
  }

  handleDigitPress(key) {
    let {complete, digits, units} = this.state

    digits = digits.indexOf('-') === -1 || digits[0] === '-'
        ? generateInitialDigits(units, key) // The current weight is complete. Reset the digits and start a new one.
        : [...digits]                       // We are already building a weight. Continue it here.

    // Move the decimal point for stone if the pounds would be out of range
    if(units === 'st') {
      digits = this.addKeyToStoneDigits(digits, key)
    }
    else {
      // replace the first '-' with the digit
      const index = digits.indexOf('-')
      if (index >= 0)
        digits[index] = key
    }

    let weight = convertStringToWeight(digits.join(''))
    if(Number.isNaN(weight) || weight === 0) {
      weight = this.state.weight
      complete = false
    }
    else {
      complete = true
    }

    const no_change = complete && this.weightIsTheSame(weight, units)
    const largeDelta = this.changeIsSuspiciouslyLarge(weight, units)

    this.setState({
      digits,
      complete,
      weight,
      largeDelta,
      no_change
    })
  }

  addKeyToStoneDigits(digits, key) {
    const string = digits.join('')
    let index = digits.indexOf('-')

    if(/^-?-st/.test(string)) { // it's a stones digit
      if(key > '3')
        return [key, 'st', '-', '-', '.', '-']
      return [key, '-', 'st', '-', '-', '.', '-']
    }

    if(/^[0-9]+st-/.test(string)) { // it's the first pounds digit
      digits[index] = key
      if(key > '1') { // if the first digit is not 1 is must be single digit
        digits[index+1] = ''
      }
      return digits
    }

    if(/^[0-9]+st1-/.test(string)) { // it's the second pounds digit
      if(key > '3') { // if the second digit is more than 4 it must a decimal
        digits[index] = ''
        index = digits.indexOf('-')
      }
    }

    // just replace the first dash
    digits[index] = key
    return digits
  }

  handleDotPress(key) {
    let {digits, units} = this.state

    if(units === 'st') {  // just ignore decimal point for kg & lb
      if(/^[0-9]+st1-/.test(digits.join(''))) {  // for st it's only relevant if the lbs component is a 1
        const index = digits.indexOf('-')
        digits[index] = ''

        const weight = convertStringToWeight(digits.join(''))
        const complete = true

        this.setState({
          digits, weight, complete
        })
      }
    }

  }

  changeIsSuspiciouslyLarge(weightAfter, unitsAfter) {
    const slimmer = this.props.slimmer
    const weightBefore = slimmer.weight
    const unitsBefore = slimmer.units

    if(weightBefore && weightBefore > 0 && weightAfter && weightAfter > 0) {
      const before = convertToPounds(weightBefore, unitsBefore)
      const after = convertToPounds(weightAfter, unitsAfter)
      return Math.abs(before - after) > 10
    }
  }

  handleUnitPress(key) {
    switch(key) {
      case 'k':
        this.changeUnits('kg')
        break
      case 'l':
      case 'p':
        this.changeUnits('lb')
        break
      case 's':
        this.changeUnits('st')
        break
    }
  }

  handleDeleteKey() {
    // Delete the last digit
    const {digits} = this.state
    for(let i = digits.length-1; i >= 0; i--) {
      if(DIGIT_KEY_PATTERN.test(digits[i])) {
        if(i === 0) {
          this.resetScales()
        }
        else {
          // just delete the current digit
          digits[i] = '-'
          this.setState({
            digits,
            complete: false,
            largeDelta: false
          })
        }
        break
      }
    }
  }

  resetScales() {
    const {weight, units} = this.props.slimmer
    this.setState(this.stateWith(weight, units))
  }

  onChangeUnits(event) {
    this.changeUnits(event.target.value)
  }

  changeUnits(newUnits) {
    const {weight, units} = this.state
    if (units !== newUnits) {
      const weightInNewUnits = convertWeightUnits(weight, units, newUnits)
      this.setState(this.stateWith(weightInNewUnits, newUnits))
    }
  }

  handleIncrementPress(key) {
    if(key === '+') this.incrementWeight()
    else if(key === '-') this.decrementWeight()
  }

  incrementWeight() {
    this.changeWeight(+0.1)
  }

  decrementWeight() {
    this.changeWeight(-0.1)
  }

  changeWeight(delta) {
    const {weight, units} = this.state
    if(units === 'st') {
      delta = delta / POUNDS_PER_STONE
    }
    this.setState(this.stateWith(weight+delta, units))
  }

  handleSubmit() {
    const {weight, units, complete} = this.state
    if(! complete) return

    const data = {
      weight: {
        value: weight,
        units
      }
    }
    this.props.postWeight(this.props.slimmer, data)
    this.close()
  //   TODO have to wait for confirmation
  }

  close() {
    const onClose = this.props.onClose
    onClose && onClose()
  }

}

const mapStateToProps = (state, props) => {
  const slimmer = currentSlimmer(state)
  return {
    ...props,
    slimmer
  }
}


const mapDispatchToProps = {
  postWeight,
}

export default connect(mapStateToProps, mapDispatchToProps)(Scale)

