import React, {Component} from 'react'


let hidden = null;
let visibilityChange = null;
if (typeof document.hidden !== 'undefined') { // Opera 12.10 and Firefox 18 and later support
  hidden = 'hidden';
  visibilityChange = 'visibilitychange';
} else if (typeof document.msHidden !== 'undefined') {
  hidden = 'msHidden';
  visibilityChange = 'msvisibilitychange';
} else if (typeof document.webkitHidden !== 'undefined') {
  hidden = 'webkitHidden';
  visibilityChange = 'webkitvisibilitychange';
}


export default class WithPolling extends Component {
  constructor(props) {
    super(props)

    this.timeout = props.interval
    this.tick = this.tick.bind(this)
    this.onVisibilityChange = this.onVisibilityChange.bind(this)
  }

  render() {
    return this.props.children
  }

  componentDidMount() {
    this.tick()
    document.addEventListener(visibilityChange, this.onVisibilityChange, false)
  }

  componentWillUnmount() {
    this.stopPolling()
    document.removeEventListener(visibilityChange, this.onVisibilityChange)
  }

  onVisibilityChange() {
    // Detect that the browser tab is now/no longer visible
    if (document[hidden]){
      this.timeout = this.props.longDelay
    }
    else {
      this.resetPolling()
    }
  }

  updateAfterDelay() {
    this.timer = setTimeout(this.tick, this.timeout * 1000)
    this.timeout = this.timeout + this.props.decay
  }

  tick() {
    this.props.onTick()
    this.updateAfterDelay()
  }

  stopPolling() {
    clearTimeout(this.timer)
  }

  resetPolling() {
    this.timeout = this.props.interval
    this.stopPolling()
    this.tick()
  }
}


