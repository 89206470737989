import React, {Component} from 'react'
import {connect} from 'react-redux'

import {t} from '../lib/localize'
import {setPageTitle} from '../lib/dom'

import SubmitButton from '../components/forms/SubmitButton'
import Errors from '../components/forms/Errors'
import {updateFormState} from '../components/forms/functions'

import PasswordInput from '../components/forms/PasswordInput'
import {updatePassword} from './actions'
import {currentSlimmer} from '../slimmers/selectors'

export const MIN_PASSWORD = 6

export class ChangePassword extends Component {
  constructor(props){
    super(props)

    this.state = {
      password: '',
      confirmation: '',
      valid: false
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  render() {
    setPageTitle(t('password.change.title'))

    const {password, confirmation} = this.state
    // const {passwordUpdated} = this.props
    const errors = this.state.errors || this.props.errors || {}

    return (
        <div className='session'>
          <div className='session-panel change-password'>
            <h2>{t('password.change.title')}</h2>
            <form className='form'
                  onSubmit={this.handleSubmit}
                  onChange={this.handleChange}>

              <Errors errors={errors.base} className='base' />
              <PasswordInput id='password'
                          autoFocus={true}
                          required={true}
                          placeholder={t('password.change.placeholder')}
                          value={password}
                          errors={errors.password}
              />
              <PasswordInput id='confirmation'
                          required={true}
                          placeholder={t('password.confirm.placeholder')}
                          value={confirmation}
                          errors={errors.confirmation}
              />
              <SubmitButton label={t('password.change.submit')} />
            </form>
          </div>
        </div>
    )
  }

  handleChange(event) {
    const state = updateFormState(event)
    this.setState(state, this.validate)
  }

  handleSubmit(event) {
    event.preventDefault()
    this.setState({submitted: true})
    if(this.validate())
      this.props.updatePassword(this.props.slimmer, this.state.password)
  }

  validate() {
    const {password, confirmation} = this.state
    let errors = null
    if(password.length < MIN_PASSWORD) {
      errors = {
        password: t('password.error.too_short', {length: MIN_PASSWORD})
      }
    }
    else if(confirmation.length > 0 && password !== confirmation) {
      errors = {
        confirmation: t('password.error.no_match')
      }
    }
    this.setState({errors})
    return errors === null
  }
}

const mapStateToProps = (state) => {
  const slimmer = currentSlimmer(state)
  const {errors} = state.session
  return {
    slimmer,
    errors
  }
}


const mapDispatchToProps = {
  updatePassword,
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword)

