import {CLEAR_FLASH_MESSAGE, HIDE_MODAL, SET_FLASH_MESSAGE, SHOW_MODAL, SYSTEM_ERROR, UPDATE_VERSION} from './actions'

const initialState = {
  version: {},
  flash: []
}

export default function status(state=initialState, action) {
  const {type, version, modal, error} = action

  switch(type) {
    case UPDATE_VERSION:
      return {
        ...state,
        version: {
          current: version,
          previous: (state.version.previous || state.version.current)
        }
      }

    case SHOW_MODAL:
      // We might want to stack modals one day
      return {
        ...state,
        modal
      }

    case HIDE_MODAL:
      return {
        ...state,
        modal: false
      }

    case SYSTEM_ERROR:
      return {
        ...state,
        error
      }

    case SET_FLASH_MESSAGE:
      return {
        ...state,
        flash: [
            ...state.flash,
            error
        ]
      }

    case CLEAR_FLASH_MESSAGE:
      return {
        ...state,
        flash: []
      }

    default:
      return state
  }
}

