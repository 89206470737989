// add a settings option to log or not

// const logActions = true
const logActions = false

export const logger = store => next => action => {
  if(logActions) {
    console.group(action.type)
    console.info('dispatching', action)
  }

  let result = next(action)

  if(logActions) {
    console.log('next state', store.getState())
    console.groupEnd()
  }

  return result
}