import React, {Component} from 'react'
import {connect} from 'react-redux'

import {setPageTitle} from '../lib/dom'
import {pageLoaded} from '../lib/analytics'

import TitleBar from './components/TitleBar'
import ScrollIntoView from '../components/ScrollIntoView'
import ProfilePicture from './components/ProfilePicture'

import {showModal} from '../app/actions'

import ProgressChart from '../weights/ProgressChart'
import DailyExcess from '../weights/DailyExcess'

import {loadSlimmer} from './actions'
import ProfileDetails from './ProfileDetails'
import {currentSlimmer, findSlimmer} from './selectors'

export class Profile extends Component {
  render() {
    const {slimmer} = this.props
    setPageTitle(slimmer.name || slimmer.id)

    return (
        <div className='profile-with-tabs'>
          <div className='slimmer profile'>
            <TitleBar slimmer={slimmer} />
            <ScrollIntoView>
              <div className='content'>
                <ProfilePicture slimmer={slimmer} changeOK={true} />
                <DailyExcess slimmer={slimmer} title={true} border={true}/>
                <ProgressChart slimmer={slimmer} title={true}/>
              </div>
            </ScrollIntoView>
          </div>

          <ProfileDetails slimmer={slimmer} />
        </div>
    )
  }

  componentDidMount() {
    const {slimmer} = this.props
    if(! slimmer.loaded) {
      this.props.loadSlimmer(slimmer.id)
    }

    setPageTitle(slimmer.name || slimmer.id)
    pageLoaded()
  }
}

function getSlimmer(state, props) {
  const slimmer = props.match.params.id === 'current'
      ? currentSlimmer(state)
      : findSlimmer(state, props.match.params.id)
  return slimmer || {}
}

const mapStateToProps = (state, props) => {
  const slimmer = getSlimmer(state, props)

  return {
    ...props,
    slimmer
  }
}

const mapDispatchToProps = {
  loadSlimmer,
  showModal
}

export default connect(mapStateToProps, mapDispatchToProps)(Profile)
