import {reloadApp} from '../navigation/redirects'

export function getVersion(state) {
  return state.app.version
}

export function getFlashMessages(state) {
  return state.app.flash
}

export function reloadIfNewVersionAvailable(version) {
  if(! (version && version.current && version.previous)) {
    return
  }

  if(version.current !== version.previous) {
    reloadApp()
  }
}

