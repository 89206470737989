import {convertToMap, removeKeyFromMap} from '../lib/collections'
import {SLIMMERS_LOADED, UPDATE_SETTINGS_FAILED, UPDATED_SETTINGS} from './actions'

import {REGISTERED, SIGNED_IN, SIGNED_OUT} from '../session/actions'

const initial = {
  by_id: {}
}

export default function slimmers(state=initial, action) {
  const {type, slimmer, slimmers, errors} = action

  switch(type) {
    case SLIMMERS_LOADED:
      return mergeNewSlimmers(state, slimmers)

    case REGISTERED:
    case SIGNED_IN:
      return mergeNewSlimmers(state, [slimmer])

    case SIGNED_OUT:
      return {}

    case UPDATED_SETTINGS:
      // Have to remove original first in case the id changed
      const withoutOriginal = removeSlimmer(state, action.before)
      return mergeNewSlimmers(withoutOriginal, [slimmer])

    case UPDATE_SETTINGS_FAILED:
      return {
        ...state,
        errors
      }

    default:
      return state
  }
}

function mergeNewSlimmers(state, slimmers) {
  slimmers = slimmers.map(slimmer => markAsLoaded(slimmer))
  const newSlimmersById = convertToMap(slimmers)

  const by_id = {
    ...(state.by_id),
    ...(newSlimmersById)
  }

  return {
    ...state,
    by_id
  }
}

function markAsLoaded(slimmer) {
    return {
      ...slimmer,
      loaded: true
    }
}

function removeSlimmer(state, slimmer) {
  const by_id = removeKeyFromMap(state.by_id, slimmer.id)
  return {
    ...state,
    by_id
  }
}
