import React from 'react'
import {empty} from '../lib/collections'

export function ResponseOptions({exchange, onClick}) {
  const responses = exchange.responses
  if(empty(responses)) return null

  return <div className='responses'>
    {responses.map(
        (response) => <a className='response option'
                         key={response.key}
                         onClick={onClick}
                         data-key={response.key}>{response.text}</a>
    )}
  </div>
}

