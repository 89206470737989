import React from 'react'
import {connect} from 'react-redux'
import {t} from '../lib/localize'
import {signOut} from './actions'

export function SignOutLink ({signOut}) {
  return <a href='#' className='sign-out' onClick={signOut}>{t('sessions.signout.link')}</a>
}

const mapDispatchToProps = {
  signOut
}

export default connect(null, mapDispatchToProps)(SignOutLink)