import React, {Component} from 'react'
import {t} from '../lib/localize'
import {pathToPrivacyPolicy} from '../navigation/routes'
import Dialog from '../components/dialogs/Dialog'

export default class PrivacyPolicy extends Component {
  constructor(props) {
    super(props)
    this.state = {
      show: false
    }

    this.onClickOpen = this.onClickOpen.bind(this)
    this.onClose = this.onClose.bind(this)
  }

  render() {
    const label = t('pages.privacy.link')
    const {show} = this.state

    const dialog =  <Dialog open={true} onClose={this.onClose} className='privacy-dialog'>
      <iframe src={pathToPrivacyPolicy()} className='privacy-frame' frameBorder='no' />
    </Dialog>

    return <React.Fragment>
      <a href={pathToPrivacyPolicy()} className='privacy' onClick={this.onClickOpen}>{label}</a>
      {show && dialog}
    </React.Fragment>
  }

  onClickOpen(event) {
    event.preventDefault()
    this.setState({show: true})
  }

  onClose() {
    this.setState({show: false})
  }
}



