import React from 'react'
import TimeAgo from '../../components/TimeAgo'
import CommentBox from './CommentBox'
import CommentList from './CommentList'

export default function Activity({activity, canComment, onClickReaction}) {
  // TODO show an icon with each one

  return <React.Fragment>
    <div className='activity'>
      <div className='when'><TimeAgo time={activity.created_at} /></div>
      <div className='what'>{activity.text}</div>
    </div>

    <ul className='comments'>
      <CommentList comments={activity.comments} onClickReaction={onClickReaction} />
      {canComment && <CommentBox activity={activity} />}
    </ul>
  </React.Fragment>
}


