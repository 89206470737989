import React from 'react'
import {Digit} from './Digits'

export default function Readout({digits, warning}) {
  return (
      <div className={`readout ${warning ? 'warning' : ''}`}>
        {digits.map((digit, index) => <Digit key={index}>{digit}</Digit>)}
      </div>
  )
}
