import React, {Component} from 'react'
import * as ReactDOM from 'react-dom'

import {findModalRoot} from '../../lib/dom'

export default class Modal extends Component {
  constructor(props) {
    super(props)

    this.handleEscape = this.handleEscape.bind(this)
    this.onBackgroundClicked = this.onBackgroundClicked.bind(this)
  }

  render() {
    const {className} = this.props
    const css = `modal ${className || ''}`

    const modal = (
        <div className={css} onClick={this.onBackgroundClicked}>
          {this.props.children}
        </div>
    )

    return ReactDOM.createPortal(modal, findModalRoot())
  }

  componentDidMount(){
    document.addEventListener('keydown', this.handleEscape, false);
  }

  componentWillUnmount(){
    document.removeEventListener('keydown', this.handleEscape, false);
  }

  closeDialog() {
    this.props.onClose && this.props.onClose()
  }

  onBackgroundClicked() {
    this.closeDialog()
  }

  handleEscape(event){
    if(event.keyCode === 27) {
      this.closeDialog()
    }
  }

}

