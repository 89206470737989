import React from 'react'
import {POUNDS_PER_STONE} from '../conversions'

export function Digit({children=''}) {
  const digit = children
  const css = `digit ${type(digit)}`
  return <div className={css}>{digit}</div>
}

function type(digit) {
  switch (digit) {
    case '-':
      return 'dash'
    case '.':
      return 'point'
    default:
      if (/[0-9]/.test(digit)) return 'number'
      else return 'units'
  }
}



export function convertToDigits(weightInUnits, units) {
  if (weightInUnits == null || Number.isNaN(weightInUnits) || weightInUnits === 0)
    return generateInitialDigits(units)

  const digits = []

  if(units === 'st') {
    const one_decimal_place = Math.round(weightInUnits * POUNDS_PER_STONE * 10) /10
    const stone = Math.floor(weightInUnits)
    const pounds = Math.floor(one_decimal_place % POUNDS_PER_STONE)
    const fraction = Math.floor(one_decimal_place * 10 % 10)

    appendDigits(fraction, digits)
    digits.push('.')
    appendDigits(pounds, digits)
    digits.push('st')
    appendDigits(stone, digits)
  }
  else {
    const one_decimal_place = Math.round(weightInUnits * 10) / 10
    const integer = Math.floor(one_decimal_place)
    const fraction = Math.floor(one_decimal_place * 10 % 10)

    digits.push(units)
    appendDigits(fraction, digits)
    digits.push('.')
    appendDigits(integer, digits)
  }

  return digits.reverse()
}

export function convertStringToWeight(string) {
  const inStone = string.includes('st')
  if(inStone) {
    const parts = string.split('st')
    const stonePart = parts[0]
    const poundPart = parts.length === 2 && parts[1].length > 0 ? parts[1] : '0'

    if(stonePart.includes('-')) // the stone number is incomplete
      return NaN

    const stone = parseFloat(stonePart)
    const pounds = parseFloat(poundPart.replace(/-/g, '0'))
    return stone + pounds / POUNDS_PER_STONE
  }

  if(string.includes('-.')) // there's a dash before the decimal point
    return NaN

  const numbersOnly = string.replace(/[^0-9.]/g, '')
  return parseFloat(numbersOnly)
}

export function generateInitialDigits(units, firstDigit) {
  // The assumption is that most people's weight falls in the range 50lb to 500lb
  // so we guess whether their weight will have two digits or three digits by the first digit.
  // For pounds, if the first digit is 1,2,3,4, their weight will be 1xx,2xx,3xx or 4xx lbs
  // If the first digit is 5,6,7,8, or 9, their weight will be 5x,6x,7x,8x or 9x lbs
  // For kilos, we assume the range 30kg to 299kg
  // For stone, we assume the range 4st to 30st
  switch(units) {
    case 'lb':
      return firstDigit > '4'
          ? ['-', '-', '.', '-', 'lb']
          : ['-', '-', '-', '.', '-', 'lb']

    case 'kg':
      return firstDigit > '2'
          ? ['-', '-', '.', '-', 'kg']
          : ['-', '-', '-', '.', '-', 'kg']

    case 'st':
      return ['-', '-', 'st', '-', '-', '.', '-']

    default:
      return ['']
  }
}

function appendDigits(value, digits) {
  if (value === 0) {
    digits.push('0')
  }

  let remainder = value
  while (remainder > 0) {
    const digit = remainder % 10
    remainder = Math.floor(remainder / 10)
    digits.push(digit.toString())
  }
}

