export const exceptions = store => next => action => {
  try {
    return next(action)
  } catch (error) {
    console.error('Exception: ', error)
    // What is Raven?
    // Raven.captureException(err, {
    //   extra: {
    //     action,
    //     state: store.getState()
    //   }
    // })
    throw error
  }}