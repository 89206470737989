import {applyMiddleware, combineReducers, createStore} from 'redux'
import thunk from 'redux-thunk'

import {logger} from '../middleware/logger'
import {exceptions} from '../middleware/exceptionHandler'

import app from '../app/reducers'
import slimmers from '../slimmers/reducers'
import activities from '../slimmers/activities/reducers'
import session from '../session/reducers'
import conversations from '../conversations/reducers'

import {SIGNED_OUT} from '../session/actions'

const reducers = combineReducers({
  app,
  slimmers,
  activities,
  conversations,
  session,
})

const rootReducer = (state, action) => {
  if (action.type === SIGNED_OUT) {
    state = undefined
  }

  return reducers(state, action)
}

const middleware = applyMiddleware(thunk, logger, exceptions)
export const store = createStore(rootReducer, middleware)

