import React from 'react'
import {t} from '../lib/localize'
import Button from '../components/Button'
import {connect} from 'react-redux'
import {showModal} from '../app/actions'

export function WeighInButton({slimmer, showModal}) {
  return (
      <Button className={'weigh-in call-to-action'}
              enabled={slimmer.weigh_in_due}
              onClick={() => showModal({id: 'weigh-in'})}>
        {t('button.icons.weigh-in')}
      </Button>
  )
}

const mapDispatchToProps = {
  showModal,
}

export default connect(null, mapDispatchToProps)(WeighInButton)
