// React gives you a warning that the field has to be either read only or have a change handler.
export const suppressTheStupidWarning = () => {}

export function updateFormState(event) {
  const id = event.target.name || event.target.id
  const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value
  return {
    [id]: value
  }
}

