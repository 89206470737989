import React from 'react'

export const BACKSPACE_GLYPH = '⌫'

const keys = [
    '1', '2', '3',
    '4', '5', '6',
    '7', '8', '9',
    '.', '0', BACKSPACE_GLYPH
]

export default function Keypad({onClick}) {
  return (
      <div className='keypad'>
        {keys.map(key => <a className='key' key={key} onClick={() => onClick(key)}>{key}</a>)}
      </div>
  )
}


