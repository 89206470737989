import React from 'react'
import {Link} from 'react-router-dom'
import {t} from '../lib/localize'
import {pathToSignIn} from '../navigation/routes'

export default function SignInLink({className}) {
  const css = `${className} sign-in`
  return <Link to={pathToSignIn()} className={css}>{t('sessions.signin.link')}</Link>
}


