import React from 'react'
import {Link} from 'react-router-dom'
import {showModal} from '../../app/actions'
import {connect} from 'react-redux'
import {t} from '../../lib/localize'

export const defaultAvatar = '😐'

export function avatarFor(slimmer) {
  return slimmer.avatar || defaultAvatar
}

function ProfilePicture ({slimmer, link, changeOK, showModal}) {
  slimmer = slimmer || {}

  const prompt = changeOK && slimmer.is_me && slimmer.avatar === null
      ? <div className='link-to-change'>{t('settings.choose-avatar.link')}</div>
      : null
  const pic = <div className='profile-picture'>{avatarFor(slimmer)}{prompt}</div>

  if(link && slimmer.url) {
    return <Link to={slimmer.url}>{pic}</Link>
  }

  if(changeOK && slimmer.is_me) {
    return <a href='#' title={t('settings.choose-avatar.tooltip')}
              onClick={() => showModal({id: 'choose-avatar'})}
            >{pic}</a>
  }

  return pic
}

const mapDispatchToProps = { showModal }
export default connect(null, mapDispatchToProps)(ProfilePicture)
