// TODO move this to the rails en.yml


const translations = {
  action: {},
  activities: {
    list: {
      title: 'Activity'
    }
  },
  button: {
    ok: 'save',
    cancel: 'cancel',
    close: '✗',
    hide: 'hide',
    menu: '☰',
    save: 'Save',
    icons:{
      'weigh-in': 'Enter Your Weight',
    },
  },
  characters: {
    cynthia: {
      name: 'Doctor Cynthia',
      avatar: '👩🏽‍⚕️'
    }
  },
  chart: {
    title: 'Progress Chart'
  },
  conversation: {
    cynthia: {
      talk_now: 'Visit Doctor Cynthia',
      link: "Doctor Cynthia's Office",
      title: "Doctor Cynthia's Office"
    },
    prompt: {
      name: 'Please call me…'
    },
    responses: {prompt: 'Choose a response from the options below.'},
    speech: {
      pending: '…'
    }
  },
  deficit: {
    title: 'Daily Deficit',
  },
  excess: {
    title: 'Daily Excess',
  },
  flash: {
    cookies: {
      withdrawn: "Oh dear! The website won't work without cookies."
    },
    // These come from sorcery password reset.
    invalid_token: 'There\'s something wrong with the token. Sorry. \nPlease request another.',
    token_expired: 'That reset code has expired. Please request another.',
    that_is_private: 'That\'s private!',
    user_not_found: 'Could not find a request with that token. \nPlease request another.',
  },
  logo: {
    main: {
      png: '/apple-touch-icon-precomposed.png',
      alt: 'Slimming with Strangers logo'
    }
  },
  pages: {
    privacy: {
      link: 'Privacy Policy'
    }
  },
  password: {
    change: {
      confirmation: 'Your password has been updated.',
      placeholder: 'Enter your new password',
      submit: 'Save Password',
      title: 'Choose a new password',
      link: 'Change password',
    },
    confirm: {
      placeholder: 'Enter your new password again',
    },
    error: {
      no_match: 'Passwords don\'t match.',
      too_short: 'Must be at least %{length} characters.',
    },
    reset: {
      link: 'Forgot your password?',
      submit: 'Send Request',
      title: 'Reset Password',
      sent: 'A password reset email is on its way to %{email}.'
    }
  },
  sessions: {
    email: {
      placeholder: 'Enter your email'
    },
    password: {
      placeholder: 'Enter your password'
    },
    signin: {
      button: 'Sign In',
      link: 'Sign In',
      submit: 'Sign In',
      title: 'Sign In',
      welcome: 'Welcome back!'
    },
    signout: {
      button: 'Sign Out',
      link: 'Sign Out'
    }
  },
  registration: {
    button: {
      submit: 'Join'
    },
    terms: {
      label: 'Accept the terms and conditions',
      required: 'You must accept the terms and conditions to continue.',
    },
    title: 'Create an Account',
    link: 'Join Now',
    short_link: 'Join',
    link_preamble: 'Need an account? ',
  },
  slimmers: {
    count: {
      one: '1 slimmer',
      other: '%{count} slimmers',
    },
    settings: {
      avatar: {
        label: 'Avatar',
        prompt: 'Change your Avatar',
      },
      email: {
        label: 'Email'
      },
      name: {
        label: 'Name'
      },
      changed: 'Your settings have been updated.',
      target_weight: {
        label: 'Target weight (%{units})'
      },
      units: {
        label: 'Preferred units'
      },
    },
    status: {
      lost: '?',
      new: 'new',
      unknown: '?',
    }
  },
  settings: {
    icon: '⚙️',
    link: 'Settings',
    title: 'Edit Settings',
    'choose-avatar': {
      title: 'Choose your Avatar',
      tooltip: 'Click to change your avatar',
      link: 'click to change',
      prompt: 'Pick an emoji',
    },
  },
  site: {
    name: 'Slimming with Strangers',
    title: 'Slimming with Strangers'
  },
  ui: {
    icons: {
      loading: 'icons/loading.gif',
      all: 'icons/icons.svg'
    },
    messages: {
      'loading': 'Loading…',
    },
  },
  weight: {
    already_entered: 'You already entered your weight today but you can change it if you like.',
    current: '%{weight} %{units}',
    chart: {
      alt: 'Progress chart for %{name}',
      empty: {
        mine: "We'll show your progress here after a few more weigh-ins.",
        theirs: "We'll show %{name}'s progress here after a few more weigh-ins.",
      }
    },
    instructions: 'Enter your weight using the keypad above.',
    scale: {
      prompt: 'Enter your weight',
    },
    button: {
      no_change: 'Stayed the Same'
    }
  }
}


let lookup = function (key) {
  let text = key
  let hash = translations

  for (var part of key.split('.')) {
    let found = hash[part]

    if (found === undefined) {
      console.log(`no translation found for ${key}`) // TODO log this to server?
      break
    }
    else if (typeof found === 'string') {
      text = found
      break
    }
    else if (typeof found === 'object' && found.other) {
      text = found
      break
    }
    else {
      hash = found
    }
  }

  return text
}

export function t(key, args={}) {
  const entry = lookup(key)

  let text = entry
  if (typeof(entry) === 'object') {
    const count_arg = args.count
    if (count_arg === 1 && entry.one) {
      text = entry.one
    }
    else {
      text = entry.other
    }
  }

  for(var key in args) {
    const keyString = `%{${key}}`
    text = text.replace(keyString, args[key])
  }

  return text
}