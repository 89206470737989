import {CONVERSATION_LOADED, NEXT_EXCHANGE_LOADED, RESPONSE_POSTED} from './actions'

const initial = {
  exchanges: [],
  current: null
}

export default function conversation(state=initial, action) {
  const {type, conversation, npc, errors} = action

  switch(type) {
    case CONVERSATION_LOADED:
      const {exchanges} = conversation
      return {
        ...state,
        exchanges
      }

    case NEXT_EXCHANGE_LOADED:
      const {current} = conversation
      const done = current == null
      return {
        ...state,
        current,
        done
      }

    case RESPONSE_POSTED:
      const {completed} = conversation
      return {
        ...state,
        exchanges: [
            ...state.exchanges,
            completed
        ]
      }

    default:
      return state
  }
}
