import { PureComponent} from "react"

class ScrollIntoView extends PureComponent {
  componentDidMount = () => setTimeout(scroll, 1)
  render = () => this.props.children
}

function scroll() {
  if(window.pageYOffset !== 0)
    window.scrollTo(0, 10)
}

export default ScrollIntoView