import server from '../server/SlimmingServer'

import {setFlashMessage, storeVersion} from '../app/actions'
import {extractValidationErrors} from '../lib/actions'
import {t} from '../lib/localize'
import {redirectToProfile} from '../navigation/redirects'
import {setCurrentSlimmer} from '../session/actions'
import {loadActivities} from './activities/actions'

export const SLIMMERS_LOADED = 'slimmers loaded'
export const LOAD_SLIMMERS_FAILED = 'load slimmers failed'

export const UPDATING_SETTINGS = 'updating settings'
export const UPDATED_SETTINGS = 'updated settings'
export const UPDATE_SETTINGS_FAILED = 'update settings failed'

export function loadSlimmers() {
  return async function(dispatch) {
    const response = await server.loadSlimmers()
        .catch(error => dispatch(loadSlimmersFailed(error)))
    if (response) {
      dispatch(storeVersion(response.version))
      dispatch(slimmersLoaded(response.slimmers))
    }
  }
}

export function slimmersLoaded(slimmers) {
  return { type: SLIMMERS_LOADED, slimmers }
}

export function loadSlimmersFailed(error) {
  return { type: LOAD_SLIMMERS_FAILED, error }
}

export function loadSlimmer(id) {
  return async function (dispatch) {
    const response = await server.loadSlimmer(id)
        .catch(error => dispatch(loadSlimmersFailed(error)))
    if(response.version)  dispatch(storeVersion(response.version))
    if(response.slimmers) dispatch(slimmersLoaded(response.slimmers))
  }
}

export function postWeight(slimmer, weight) {
  return async function (dispatch) {
    await server.postWeight(slimmer, weight)
    dispatch(loadSlimmers())
    dispatch(loadActivities(slimmer.id))
    redirectToProfile(slimmer)
  }
}

export function updateSettings(slimmer, settings) {
  return async function (dispatch) {
    dispatch(updatingProfile())
    const response = await server.updateSettings(slimmer, settings)
        .catch(error => dispatch(updateSettingsFailed(error)))

    if (response) {
      const updated = response.slimmers[0]
      dispatch(updatedProfile(slimmer, updated))
      dispatch(setCurrentSlimmer(updated))
      dispatch(setFlashMessage(t('slimmers.settings.changed')))

      redirectToProfile(updated)
    }
  }
}

export function updatingProfile() {
  return { type: UPDATING_SETTINGS }
}

export function updatedProfile(before, slimmer) {
  return { type: UPDATED_SETTINGS, before, slimmer }
}

export function updateSettingsFailed(error) {
  const errors = extractValidationErrors(error)
  return { type: UPDATE_SETTINGS_FAILED, errors }
}



// export function refreshSlimmers(since) {
//   return function(dispatch) {
//     return server.refreshSlimmers(since)
//         .then(data => dispatch(slimmersLoaded(data.slimmers)))
//   }
// }

