import React from 'react'

const dateFormat = new Intl.DateTimeFormat('default', { year: 'numeric', month: 'long', day: 'numeric' })

function TimeAgo ({time, brief=true}) {
  if (time === undefined)
    return null

  const now = Date.now()
  const date = Date.parse(time)
  const remaining = (now - date) / 1000
  let text

  if (remaining < 0) {
    text = brief ?
        '?' :
        'in the future'
  }
  else {
    let minutes = Math.floor(remaining / 60)
    let hours = Math.floor(remaining / 60 / 60)
    let days = Math.floor(remaining / 60 / 60 / 24)

    if(days > 30 && !brief) {
      text = dateFormat.format(date)
    }
    else if (days > 365){
      let years = Math.floor(remaining / 60 / 60 / 24 / 365)
      text = brief ?
          `${years}y` :
          `${years} years ago`
    }
    // else if (days > 60){
    //   let months = Math.floor(remaining / 60 / 60 / 24 / 30)
    //   text = brief ? `${months}mth` : `${months} months ago`
    // }
    else if (days > 14){
      let weeks = Math.floor(remaining / 60 / 60 / 24 / 7)
      text =  brief ?
          `${weeks}w` :
          `${weeks} weeks ago`
    }
    else if (days > 1){
      text = brief ?
          `${days}d` :
          `${days} days ago`
    }
    else if(days === 1) {
      text = brief ?
          '1d' :
          'about a day ago'
    }
    else if(hours > 1) {
      text = brief ?
          `${hours}h` :
          `${hours} hours ago`
    }
    else if(hours === 1) {
      text = brief ?
          '1h' :
          "about an hour ago"
    }
    else if(minutes > 1) {
      text = brief ?
          `${minutes}m` :
          `${minutes} minutes ago`
    }
    else {
      text = brief ?
          '1m' :
          'just now'
    }
  }

  return (
      <span className='time-ago'>{text}</span>
  )
}

export default TimeAgo
