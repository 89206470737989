import React from 'react'
import ProgressChart from '../weights/ProgressChart'
import ProfilePicture from './components/ProfilePicture'
import DailyExcess from '../weights/DailyExcess'
import TitleBar from './components/TitleBar'
import SlimmerLink from './SlimmerLink'

export default function Card({slimmer}) {
  return (
      <div className='slimmer card'>
        <SlimmerLink slimmer={slimmer}>
          <TitleBar slimmer={slimmer} />
          <div className='row'>
            <ProfilePicture slimmer={slimmer} />
            <ProgressChart slimmer={slimmer} />
            <DailyExcess slimmer={slimmer} border={true} />
          </div>
        </SlimmerLink>
      </div>
  )
}
