import React from 'react'
import {connect} from 'react-redux'
import {Link} from 'react-router-dom'

import Logo from './Logo'
import {t} from '../lib/localize'

import {isOtherSlimmersPage, pathToSlimmers} from '../navigation/routes'
import MainMenu from './MainMenu'
import CurrentWeight from '../status/CurrentWeight'
import {currentSlimmer} from '../slimmers/selectors'
import SessionLinks from '../session/SessionLinks'

export function Header(props) {
  if(props.me) { // signed in
    return (
        <div id='header' className='header'>
          <Logo path='/slimmers'/>
          <MainAction {...props} />
          <MainMenu {...props} />
        </div>
    )
  }

  return ( // not signed in
      <div id='header' className='header'>
        <Logo path='/home'/>
        <AppTitle />
        <SessionLinks />
      </div>
  )
}

function MainAction({me}) {
  if(isOtherSlimmersPage(me) || me.weigh_in_due)
    return <AppTitle/>

  return <CurrentWeight slimmer={me}/>
}

function AppTitle() {
  return <h1 className='title'><Link to={pathToSlimmers()}>{t('site.title')}</Link></h1>
}

const mapStateToProps = state => {
  const me = currentSlimmer(state)
  return { me }
}

export default connect(mapStateToProps)(Header)
