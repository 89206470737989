import server from '../server/SlimmingServer'
import {loadSlimmer} from '../slimmers/actions'

export const CONVERSATION_LOADED = 'conversation loaded'
export const NEXT_EXCHANGE_LOADED = 'next exchange loaded'
export const RESPONSE_POSTED = 'response posted'

export function fetchConversation(npc) {
  return async function (dispatch) {
    const response = await server.fetchConversation(npc)
    dispatch(conversationLoaded(response.conversation))
  }
}

export function fetchNextExchange(npc) {
  return async function (dispatch) {
    const response = await server.fetchNextExchange(npc)
    dispatch(exchangeLoaded(response.conversation))
  }
}

export function postResponse(slimmer, response) {
  return async function (dispatch) {
    const data = await server.postResponse(response)
    dispatch(responsePosted(data.conversation))
    dispatch(exchangeLoaded(data.conversation))
    dispatch(loadSlimmer(slimmer.id)) // refetch slimmer in case any details changed
  }
}

export function responsePosted(conversation) {
  return { type: RESPONSE_POSTED, conversation }
}

export function exchangeLoaded(conversation={}) {
  return { type: NEXT_EXCHANGE_LOADED, conversation }
}

export function conversationLoaded(conversation) {
  return { type: CONVERSATION_LOADED, conversation }
}

