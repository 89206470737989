import React from 'react'
import {NPC} from '../characters/NPC'
import {Character} from '../characters/Character'
import {t} from '../lib/localize'
import {any} from '../lib/collections'

export function Panel({exchange, slimmer, active}) {
  const response_available = any(exchange.responses) && ! exchange.response
  const response = exchange.response
      ? <div className='speech response '>{exchange.response}</div>
      : <div className='speech response pending'>{t('conversation.speech.pending')}</div>

  const css = active ? 'panel active' : 'panel'
  const speechCss = exchange.text.length > 100 ? 'long' : 'short'

  return <div className={css}>
    <NPC name='cynthia'/>
    <div className={`speech npc ${speechCss}`}>{exchange.text}</div>
    <Character slimmer={slimmer}/>
    {response}
    {response_available && <div className='instructions'>{t('conversation.responses.prompt')}</div>}
  </div>
}

