import React from 'react'
import {connect} from 'react-redux'
import {showModal} from '../app/actions'
import Readout from '../weights/scale/Readout'
import {convertToDigits} from '../weights/scale/Digits'

export function CurrentWeight({slimmer, showModal}) {
  const {weight, units} = slimmer
  const digits = convertToDigits(weight, units)

  return (
      <a className='current-weight' onClick={() => showModal({id: 'weigh-in'})}><Readout digits={digits} /></a>
  )
}



const mapDispatchToProps = { showModal }
export default connect(null, mapDispatchToProps)(CurrentWeight)

