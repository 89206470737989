import {
  PASSWORD_REQUEST_SENT,
  REMEMBER_EMAIL, PASSWORD_REQUEST_FAILED,
  SET_CURRENT_SLIMMER,
  SIGN_IN_FAILED,
  SIGNED_IN,
  SIGNED_OUT, UPDATE_PASSWORD_FAILED, REGISTERED, REGISTRATION_FAILED
} from './actions'

const initialState = {}

export default function status(state=initialState, action) {
  const {type, slimmer, email, errors} = action

  switch(type) {
    case SIGNED_IN:
    case REGISTERED:
    case SET_CURRENT_SLIMMER:
      const currentSlimmerId = slimmer.id
      return {
        ...state,
        currentSlimmerId,
        errors: {}
      }

    case SIGN_IN_FAILED:
      return {
        ...state,
        errors
      }

    case SIGNED_OUT:
      return {}

    case REGISTRATION_FAILED:
      return {
        ...state,
        errors
      }

    case PASSWORD_REQUEST_SENT:
      return {
        ...state,
        errors: {},
        passwordResetSentTo: email
      }

    case PASSWORD_REQUEST_FAILED:
      return {
        ...state,
        errors,
        passwordResetSent: false,
      }

    case UPDATE_PASSWORD_FAILED:
      return {
        ...state,
        errors,
      }

    case REMEMBER_EMAIL:
      return {
        ...state,
        email,
        errors: {}
      }

    default:
      return state
  }
}

