import React from 'react'
import {connect} from 'react-redux'
import {BrowserRouter} from 'react-router-dom'
import {Route} from 'react-router'

import SystemMessages from '../components/SystemMessages'
import FlashMessages from '../components/FlashMessages'
import ModalDialogs from '../components/dialogs/ModalDialogs'

import {getVersion, reloadIfNewVersionAvailable} from './version'

import Content from './Content'
import Header from './Header'
import StatusBar from '../status/StatusBar'

import {currentSlimmer} from '../slimmers/selectors'

function App(props) {
  reloadIfNewVersionAvailable(props.version)

  return (
     <BrowserRouter>
        <div id='app' className='app page'>
          <FlashMessages />
          <Route component={Header} />
          <StatusBar {...props} />
          <Content {...props} />
          <ModalDialogs />
          <SystemMessages />
        </div>
      </BrowserRouter>
  )
}

const mapStateToProps = state => {
  const me = currentSlimmer(state) || {}
  const version = getVersion(state)
  return {me, version}
}

export default connect(mapStateToProps)(App)
