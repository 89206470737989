import React from 'react'
import {t} from '../lib/localize'

export default function DailyExcess({slimmer, title, border, inline}) {
  if(slimmer.new) {
    return null
  }

  let value = ''
  const borderCSS = border ? 'with-border' : ''
  const inlineCSS = inline ? 'inline' : 'block'
  let lossOrGainCSS = ''
  let label = t('excess.title')

  if (slimmer.excess === 0) {
    lossOrGainCSS = 'same'
    value = slimmer.excess
  }
  else if (slimmer.excess > 0) {
    lossOrGainCSS = 'gain'
    value = slimmer.excess
  }
  else if (slimmer.excess < 0) {
    lossOrGainCSS = 'loss'
    value = -slimmer.excess
    label = t('deficit.title')
  }
  else {
    lossOrGainCSS = 'unknown'
    value = t('slimmers.status.unknown')
  }

  return (
      <span className={`excess ${inlineCSS}`}>
        {title && <h2 className='title'>{label}</h2>}
        <span className={`value ${lossOrGainCSS} ${borderCSS}`}>{value}</span>
      </span>
  )
}


