import React from 'react'
import {t} from '../lib/localize'
import {getFlashMessages} from '../app/version'
import {connect} from 'react-redux'
import clearFlashMessage from '../app/actions'

export function FlashMessages({flash=[], dispatch}) {
  if(dispatch && flash.length > 0) {
    setTimeout(() => dispatch(clearFlashMessage()), 10000)
  }

  let counter = 1
  return (
      <div id='flash-messages' className='flash-messages'>
        {flash.map( message => <div key={counter++} className='message'>
          {t(message)}
        </div>)}
      </div>
  )
}

const mapStateToProps = state => {
  const flash = getFlashMessages(state)
  return {flash}
}

export default connect(mapStateToProps)(FlashMessages)
