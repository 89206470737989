import React from 'react'
import Activity from './Activity'
import {findActivities} from './selectors'
import {connect} from 'react-redux'
import {loadActivities} from './actions'
import {isSignedIn} from '../../session/selectors'

export class ActivitiesList extends React.Component {
  render() {
    const {slimmer, activities} = this.props

    if (!activities || activities.length === 0) {
      return null
    }

    return <React.Fragment>
      {/*<h3 className='title'>{t('activities.list.title')}</h3>*/}
      <ul className='activities'>
        {activities.map((activity, index) => <li key={activity.id}>
          <Activity slimmer={slimmer} activity={activity} canComment={index === 0}/>
        </li>)}
      </ul>

      {/*<a className='more' href='#'>more activity…</a>*/}
    </React.Fragment>
  }

  componentDidMount() {
    const {slimmer, activities} = this.props
    if(this.props.signedIn && !activities) {
      this.props.loadActivities(slimmer.id)
    }
  }
}

const mapStateToProps = (state, props) => {
  const {slimmer} = props
  const activities = findActivities(slimmer, state)
  const signedIn = isSignedIn(state)

  return {
    ...props,
    slimmer,
    signedIn,
    activities
  }
}

const mapDispatchToProps = {
  loadActivities,
}

export default connect(mapStateToProps, mapDispatchToProps)(ActivitiesList)
