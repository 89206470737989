import {Redirect, Route} from 'react-router'
import React from 'react'
import {connect} from 'react-redux'
import {isSignedIn} from './selectors'
import {pathToSignIn} from '../navigation/routes'

export function PrivateRoute({component, signedIn, ...props}) {
  return <Route {...props} render={props => renderIfSignedIn(component, signedIn, props)} />
}

function renderIfSignedIn(Component, signedIn, props) {
  if(signedIn)
    return <Component {...props} />

  return <Redirect to={pathToSignIn()} />
}

const mapStateToProps = (state, props) => {
  const signedIn = isSignedIn(state)
  return {
    ...props,
    signedIn
  }
}

export default connect(mapStateToProps)(PrivateRoute)
