import React, {Component} from 'react'
import {connect} from 'react-redux'
import {t} from '../../lib/localize'
import {updateSettings} from '../actions'
import {currentSlimmer} from '../selectors'
import {Picker} from 'emoji-mart'

import 'emoji-mart/css/emoji-mart.css'

class ChooseAvatar extends Component {
  constructor(props) {
    super(props)

    const {avatar} = this.props.slimmer || {}
    this.state = {avatar}

    this.handleChoice = this.handleChoice.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  render() {
    return (
        <div className='choose-avatar'>
          <div className='title'>{t('settings.choose-avatar.title')}</div>

          <Picker set='apple'
                  emoji=''
                  title={t('settings.choose-avatar.prompt')}
                  sheetSize={32}
                  onSelect={this.handleChoice}
          />
        </div>
    )
  }

  handleChoice(choice) {
    const {slimmer} = this.props
    this.props.updateProfile(slimmer, {slimmer: {avatar: choice.native}})
    this.close() // TODO wait for confirmation before closing
  }

  handleSubmit(event) {
    event.preventDefault()

    const {slimmer} = this.props
    this.props.updateProfile(slimmer, {slimmer: this.state})
  }

  close() {
    const onClose = this.props.onClose
    onClose && onClose()
  }
}

const mapStateToProps = (state, props) => {
  const slimmer = currentSlimmer(state)
  const {onClose} = props

  return {
    slimmer,
    onClose
  }
}

const mapDispatchToProps = {
  updateProfile: updateSettings,
}

export default connect(mapStateToProps, mapDispatchToProps)(ChooseAvatar)