import React, {Component} from 'react'
import {t} from '../lib/localize'
import {convertToMap} from '../lib/collections'
import Tabs from '../components/Tabs'

// TODO Render the SVG on the client
export default class ProgressChart extends Component {
  constructor(props) {
    super(props)
    this.state = {
      period: null,
      url: null
    }

    this.onClickPeriodTab = this.onClickPeriodTab.bind(this)
  }

  render() {
    const {slimmer, title} = this.props

    if (title) {
      return (
          <div className='chart-frame'>
            {title && <h2 className='chart-title'>{t('chart.title')}</h2>}
            {this.chartUrl() ? this.chart() : this.noDataMessage()}
            {this.periodTabs()}
          </div>
      )
    }

    return slimmer.new
        ? this.noDataMessage()
        : this.chart()
  }

  periodTabs() {
    const periods = this.availableCharts().map(period => period.label)
    const period = this.chartPeriod()

    if (periods.length > 0) {
      return <Tabs tabs={periods} selected={period} align='right' onClick={this.onClickPeriodTab}/>
    }
    return null
  }

  onClickPeriodTab(period) {
    this.setPeriod(period)
  }

  setPeriod(period) {
    const charts = this.availableCharts()
    const chartsByPeriod = convertToMap(charts, 'label')
    const chart = chartsByPeriod[period] || {}
    const url = chart.url || this.state.url

    this.setState({ period, url })
  }

  availableCharts() {
    const {slimmer} = this.props
    return slimmer.charts || []
  }

  chart() {
    const url = this.chartUrl()
    if(!url) {
      return <div className='blank-chart' />
    }

    const {slimmer} = this.props
    const alt = t('weight.chart.alt', {name: slimmer.name})

    return <img className='chart progress' src={url} alt={alt}/>
  }

  chartPeriod() {
    return this.state.period || this.props.slimmer.chart_period
  }

  chartUrl() {
    return this.state.url || this.props.slimmer.chart_url
  }

  noDataMessage() {
    const {slimmer} = this.props
    const message = slimmer.is_me ? t('weight.chart.empty.mine') : t('weight.chart.empty.theirs', {name: slimmer.name})
    return  <div className={'empty'}>{message}</div>
  }

}
