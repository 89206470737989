import React, {Component} from 'react'
import SignOutLink from '../session/SignOutLink'
import ChangePasswordLink from '../session/ChangePasswordLink'
import {t} from '../lib/localize'
import SettingsLink from '../slimmers/settings/SettingsLink'
import ConversationLink from '../conversations/ConversationLink'

export default class MainMenu extends Component {
  constructor(props) {
    super(props)

    this.state = {
      visible: false
    }

    this.toggle = this.toggle.bind(this)
    this.hide = this.hide.bind(this)
  }

  render() {
    if(! this.props.me.signed_in)
      return <div className='placeholder' />

    return (
        <div className='menu-container'>
          <a href='#'
             className='menu-button'
             onClick={this.toggle}>{t('button.menu')}</a>
          {
            this.state.visible && (
                <React.Fragment>
                  <div className='mask' onClick={this.hide} />
                  <ul className='menu' onClick={this.hide}>
                    <li><ConversationLink label={true}/></li>
                    <li><SettingsLink label={true}/></li>
                    <li><ChangePasswordLink /></li>
                    <li><SignOutLink /></li>
                  </ul>
                </React.Fragment>
            )
          }
        </div>
    )
  }

  toggle() {
    const visible = ! this.state.visible
    this.setState({visible})
  }

  hide() {
    this.setState({visible: false})
  }
}
