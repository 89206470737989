import * as axios from 'axios'

import {getCSRFToken} from '../lib/dom'
import {redirectToSignIn} from '../navigation/redirects'

export const Forbidden = 401
export const NotFound = 404
export const Throttled = 429
export const BadRequest = 422

export class Server {
  constructor() {
    this.available = true
  }

  get = (path) => this.send(path, 'get')
  put    = (path, data) => this.send(path, 'put', data)
  post   = (path, data) => this.send(path, 'post', data)
  delete = (path, data) => this.send(path, 'delete', data)

  send(path, method, data){
    if(!this.available) {
      const error = new Error('Server is unavailable')
      return new Promise((resolve, reject) => reject(error))
    }

    const request = {
      method: method,
      url: path,
      data: data,
      headers: {
        'X-CSRF-Token': getCSRFToken(),
        'X_REQUESTED_WITH': 'XMLHttpRequest',
        'Content-Type': 'application/json',
      },
    }

    const signingIn = data && data.credentials
    return axios(request)
        .then(response => response.data)
        .catch(error => {
              if(!signingIn && error.response.status === Forbidden) redirectToSignIn()
              else if(error.response.status === Throttled) redirectToSignIn()
              else throw error
            }
        )
  }
}
