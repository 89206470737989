import React from 'react'
import {Route} from 'react-router'

import Home from './Home'

import Slimmers from '../slimmers/Slimmers'
import Profile from '../slimmers/Profile'
import SignIn from '../session/SignIn'
import ResetPassword from '../session/ResetPassword'
import Registration from '../slimmers/Registration'
import ChangePassword from '../session/ChangePassword'
import Conversation from '../conversations/Conversation'
import ConversationMenu from '../conversations/ConversationMenu'
import PrivateRoute from '../session/PrivateRoute'

export default function Content({me}) {
  return (
      <div id='content' className='content'>
        <Route component={Home}                path='/home' />

        {<Route component={Slimmers}     path='/' exact />}
        <Route component={Slimmers}            path='/slimmers' exact />
        <PrivateRoute component={ConversationMenu}    path='/conversations' exact/>

        <Route component={Profile}             path='/slimmers/:id' exact />

        <Route component={Conversation}        path='/conversations/:id' />

        <Route component={SignIn}              path='/sign_in' />
        <Route component={Registration}        path='/register' />
        <Route component={ResetPassword}       path='/passwords/new' />
        <Route component={ChangePassword}      path='/passwords/:id/edit' />
      </div>
  )
}

