import {ACTIVITIES_LOADED} from './actions'

const initial = {
}

export default function activities(state=initial, action) {
  const {type, slimmer_id, activities} = action

  switch(type) {
    case ACTIVITIES_LOADED:
      return mergeActivities(state, slimmer_id, activities)

    default:
      return state
  }
}

function mergeActivities(state, slimmer_id, activities) {
  return {
    ...state,
    [slimmer_id]: activities
  }
}
