import React, {Component} from 'react'
import CloseButton from './CloseButton'
import Modal from './Modal'

export default class Dialog extends Component {
  constructor(props) {
    super(props)

    this.handleClose = this.handleClose.bind(this)
    this.onDialogClicked = this.onDialogClicked.bind(this)
    this.closeDialog = this.closeDialog.bind(this)
    this.handleEscape = this.handleEscape.bind(this)
  }

  render() {
    const {children, className} = this.props
    const css = `dialog ${className || ''}`

    return (
        <Modal className='for-dialog' onClose={this.closeDialog}>
          <div className={css} onClick={this.onDialogClicked}>
            <CloseButton onClick={this.handleClose}/>
            <div className='content'>{children}</div>
          </div>
        </Modal>
    )
  }

  componentDidMount(){
    document.addEventListener('keydown', this.handleEscape, false);
  }

  componentWillUnmount(){
    document.removeEventListener('keydown', this.handleEscape, false);
  }

  handleEscape(event){
    if(event.keyCode === 27) {
      this.close()
    }
  }

  onDialogClicked(event) {
    event.stopPropagation() // To prevent bubbling to background
  }

  handleClose(event) {
    event.preventDefault()
    this.closeDialog()
  }

  closeDialog() {
    const {onClose} = this.props
    onClose && onClose()
  }
}

