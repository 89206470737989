import React from 'react'
import {NPC} from '../characters/NPC'
import {currentSlimmer} from '../slimmers/selectors'
import {connect} from 'react-redux'
import {pathToTopic} from '../navigation/routes'
import {Link} from 'react-router-dom'

export function ConversationMenu({slimmer}) {
  if(slimmer.available_topics) {
    return <div className='conversation-menu panel'>
      <h2>Doctor Cynthia's Office</h2>
      <NPC name='cynthia'/>
      <p>What would you like to talk about?</p>
      <ul className='choices'>
        {
          Object.keys(slimmer.available_topics).map(topic =>
              <li key={topic}>
                <Link to={pathToTopic('cynthia', topic)}>{slimmer.available_topics[topic]}</Link>
              </li>
            )
        }
      </ul>
    </div>
  }

  return <div className='placeholder' />
}

const mapStateToProps = (state) => {
  return { slimmer: currentSlimmer(state) }
}

export default connect(mapStateToProps)(ConversationMenu)
