import React from 'react'
import Comment from './Comment'

export default function CommentList({comments, onClickReaction}) {
  if(! comments) {
    return null
  }

  return comments.map((comment) => <li key={comment.id}>
    <Comment comment={comment} onClickReaction={onClickReaction}/>
  </li>)
}


