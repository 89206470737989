import React from 'react'
import Action from '../../components/Action'
import SlimmerLink from '../SlimmerLink'
import {connect} from 'react-redux'
import {findSlimmer} from '../selectors'

export class Comment extends React.Component {
  constructor(props) {
    super(props)
    this.onClickReaction = this.onClickReaction.bind(this)
  }

  render() {
    let {comment, onClickReaction} = this.props
    const {author, text} = comment
    return <div className='comment'>
      <div className='who'><SlimmerLink slimmer={author} /></div>
      <div className='text'>{text}</div>
      <Action className='icon reaction'
              description='Share some love'
              onClick={this.onClickReaction}
      >♡</Action>
    </div>
  }

  onClickReaction(event) {
    console.log('React to me')
    event.preventDefault()
  }
}

const mapStateToProps = (state, props) => {
  const author = findSlimmer(state, props.comment.author.id)
  const comment = {
    ...props.comment,
    author
  }
  return {comment}
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(Comment)

