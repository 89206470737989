import React from 'react'
import {t} from '../lib/localize'
import {Link} from 'react-router-dom'
import {pathToRegistration} from '../navigation/routes'

export default function RegistrationLink({className, label}) {
  const css = `register ${className}`
  label = label || t('registration.link')

  return <span className='registration-link'>
    <span className='preamble'>{t('registration.link_preamble')}</span>
    <Link to={pathToRegistration()} className={css}>{label}</Link>
  </span>
}


