import {pathToHome, pathToSignIn, pathToSlimmer, pathToSlimmers} from './routes'

export function redirectToSignedOut() {
  window.location = pathToHome()
}

export function redirectToHome() {
  window.location = pathToHome()
}

export function redirectToSignIn() {
  window.location = pathToSignIn()
}

export function redirectToSlimmers() {
  window.location = pathToSlimmers()
}

export function redirectToProfile(slimmer) {
  window.location = pathToSlimmer(slimmer)
}

export function reloadApp() {
  console.log('Reloading…')
  window.location.reload()
}
