export function pathToHome() {
  return '/'
}

export function pathToSignIn() {
  return '/sign_in'
}

export function pathToRegistration() {
  return '/register'
}

export function pathToSlimmers() {
  return '/slimmers'
}

export function pathToCurrentSlimmer() {
  return '/slimmers/current'
}

export function pathToSlimmer(slimmer) {
  if(slimmer)
    return `/slimmers/${slimmer.id}`
  return '/slimmers/current'
}

export function pathToConversationsMenu() {
  return '/conversations'
}

export function pathToTopic(npc, topic) {
  return `/conversations/${topic}`
}


export function pathToPrivacyPolicy() {
  return '/privacy?embed'
}

export function isOtherSlimmersPage(me) {
  const path = window.location.pathname
  if(path === pathToCurrentSlimmer())
    return false
  if(path === pathToSlimmer(me))
    return false

  return /\/slimmers\/.+/.test(path)
}

export function isSignInPage() {
  return window.location.pathname === pathToSignIn()
}

export function isPasswordPage() {
  return window.location.pathname.startsWith('/password')
}

export function isRegistrationPage() {
  return window.location.pathname === pathToRegistration()
}


