import React from 'react'
import {Link} from 'react-router-dom'
import {t} from '../lib/localize'

export default function Logo({path = '/'}) {
  return (
      <div className='logo'>
        <Link to={path}>
          <img src={t('logo.main.png')} alt={t('logo.main.alt')}/>
        </Link>
      </div>
  )
}

