import React, {Component} from 'react'
import {connect} from 'react-redux'
import {t} from '../../lib/localize'

import NumericInput from '../../components/forms/NumericInput'
import SubmitButton from '../../components/forms/SubmitButton'
import TextInput from '../../components/forms/TextInput'
import EmailInput from '../../components/forms/EmailInput'
import Errors from '../../components/forms/Errors'
import ProfilePicture from '../components/ProfilePicture'

import {updateSettings} from '../actions'
import {currentSlimmer} from '../selectors'
import {redirectIfNotSignedIn} from '../../session/authentication'

export const MIN_NAME_LENGTH = 1
export const MAX_NAME_LENGTH = 20

export class Settings extends Component {
  constructor(props) {
    super(props)

    const {name, email, avatar, target_weight, units} = this.props.slimmer || {}
    this.state = {name, email, avatar, target_weight, units}

    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  render() {
    const slimmer = this.props.slimmer
    const {name, email, target_weight, units} = this.state
    const errors = this.props.errors || {}

    return (
        <div className='edit-settings'>
          <div className='title'>{t('settings.title')}</div>
          <form
              className='form'
              onSubmit={this.handleSubmit}
              onChange={this.handleChange}>

            <Errors errors={errors.base} className='base' />
            <TextInput id='name'
                       className='name'
                       autoFocus={true}
                       label={t('slimmers.settings.name.label')}
                       value={name}
                       minLength={MIN_NAME_LENGTH}
                       maxLength={MAX_NAME_LENGTH}
                       errors={errors.name}
            />
            <EmailInput id='email'
                        className='email'
                        label={t('slimmers.settings.email.label')}
                        value={email}
                        errors={errors.email}
            />

            <NumericInput id='target_weight'
                          className='target-weight'
                          label={t('slimmers.settings.target_weight.label', {units})}
                          value={target_weight || ''}
                          errors={errors.target_weight}
            />
            {/* Need password too*/}
            {/* Need sex too */}
            <ProfilePicture slimmer={slimmer} changeOK={true} />
            <div className='caption'>Click on your avatar to change it.</div>


            <div className='buttons'>
              <SubmitButton />
            </div>
          </form>

        </div>
    )
  }

  handleChange(event) {
    const id = event.target.id
    const value = event.target.value
    const state = {
      [id]: value
    }

    this.validate(state)
    this.setState(state)
  }

  validate(state) {
  }

  handleSubmit(event) {
    event.preventDefault()

    const updates = this.state
    const {slimmer} = this.props
    this.props.updateProfile(slimmer, {slimmer: updates})
    this.close() // TODO wait for confirmation before closing
  }

  close() {
    const onClose = this.props.onClose
    onClose && onClose()
  }
}

const mapStateToProps = (state, props) => {
  redirectIfNotSignedIn(state)

  const slimmer = currentSlimmer(state)
  const errors = state.slimmers.errors
  const {onClose} = props

  return {
    slimmer,
    onClose,
    errors,
  }
}

const mapDispatchToProps = {
  updateProfile: updateSettings,
}

export default connect(mapStateToProps, mapDispatchToProps)(Settings)