import {store} from './store'
import {t} from '../lib/localize'
import {initializeGoogleAnalytics} from '../lib/analytics'

import {loadSlimmer, slimmersLoaded} from '../slimmers/actions'
import {setCurrentSlimmer} from '../session/actions'
import {setFlashMessage} from './actions'
import {findFlashContainer} from '../lib/dom'
import {pathToCurrentSlimmer} from '../navigation/routes'

export function initialize() {
  // store.dispatch(loadImagePaths())            // Get the paths with fingerprints for images
  initializeGoogleAnalytics()
  retrieveFlashMessages()
}

export function loadCurrentSlimmer(slimmer) {
  if(slimmer) {
    store.dispatch(slimmersLoaded([slimmer]))         // create a skeleton entry for the current user
    store.dispatch(loadSlimmer(slimmer.id))                    // fetch the real user details
    store.dispatch(setCurrentSlimmer(slimmer))                 // set the current user in the app
  }
}

export function createSlimmerFromElementData(element) {
  const id = element.getAttribute('data-user-id')
  const name = element.getAttribute('data-user-name')
  const url = pathToCurrentSlimmer()

  if( !id ) return null

  return {
    id,
    name,
    url
  }
}

export function retrieveFlashMessages() {
  const element = findFlashContainer()
  const message = element.getAttribute('data-alert')
  if(message) {
    store.dispatch(setFlashMessage(t(`flash.${message}`)))
  }
}

