import React from 'react'
import {connect} from 'react-redux'
import {t} from '../../lib/localize'
import {showModal} from '../../app/actions'
import Icon from '../../components/Icon'
import {isSignedIn} from '../../session/selectors'

export function SettingsLink({label=false, showModal, signedIn}) {
  const icon = <Icon>{t('settings.icon')}</Icon>

  if(! signedIn) return icon

  return <a className='settings' href='#' onClick={() => showModal({id: 'settings'})}>
    {icon}
    {label && <div className='label'>{t('settings.link')}</div>}
  </a>
}

const mapStateToProps = (state, props) => {
  const signedIn = isSignedIn(state)

  return {
    ...props,
    signedIn
  }
}

const mapDispatchToProps = {
  showModal,
}

export default connect(mapStateToProps, mapDispatchToProps)(SettingsLink)
